import axios from '@/lib/axios'

const initialState = {
  loading: false,
  examCollections: [],
}

const mutations = {
  SET_EXAM_COLLECTIONS(state, payload) {
    state.examCollections = payload
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  },
}

const actions = {
  listExamCollections({ commit }, filters = {}) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.get('/exam_collection', { params: filters })
        .then(({ data }) => {
          commit('SET_EXAM_COLLECTIONS', data)
          resolve(data)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  updateExamCollection({ commit }, { id, attributes }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.patch(`/exam_collection/${id}`, attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
}

const getters = {
  exams: state => state.examCollections,
  loading: state => state.loading,
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
