import axios from '@/lib/axios'
import generateAppointmentExtendedCondition from '@/utils/extendedAppointment'
import { APPOINTMENT, SPECIALITY } from 'amparo-enums'
import {
  always,
  cond,
  either,
  equals,
  includes,
  isEmpty,
  isNil,
  propEq,
  reject,
} from 'ramda'

const initialState = {
  activationJourney: {},
  journeys: {},
  loadingPatientData: false,
  loadingPatientSpecialConditions: false,
  patient: null,
  patients: [],
  patientSpecialConditions: {},
  patientConditions: [],
  reschedule: {},
}

const mutations = {
  SET_PATIENTS(state, payload) {
    state.patients = payload
  },
  SET_PATIENT_DATA(state, payload) {
    state.patient = payload
  },
  SET_LOADING_PATIENT_DATA(state, payload) {
    state.loadingPatientData = payload
  },
  SET_PATIENT_SPECIAL_CONDITIONS(state, payload) {
    state.patientSpecialConditions = payload
  },
  SET_PATIENT_CONDITIONS(state, payload) {
    state.patientConditions = payload
  },
  SET_LOADING_PATIENT_SPECIAL_CONDITIONS(state, payload) {
    state.loadingPatientSpecialConditions = payload
  },
  SET_ACTIVATION_JOURNEY(state, payload) {
    state.activationJourney = payload
  },
  SET_JOURNEYS(state, payload) {
    state.journeys = payload
  },
  SET_RESCHEDULE_APPOINTMENT(state, payload) {
    state.reschedule = payload
  },
}

const actions = {
  getPatient({ commit }, patientId) {
    commit('SET_LOADING_PATIENT_DATA', true)
    return new Promise((resolve, rejectPromise) => {
      axios.get(`patient/${patientId}`)
        .then(({ data }) => {
          commit('SET_PATIENT_DATA', data.patient)
          resolve(data.patient)
        })
        .catch(rejectPromise)
        .finally(() => {
          commit('SET_LOADING_PATIENT_DATA', false)
        })
    })
  },
  getCareHistory(_, { patientId, page }) {
    return new Promise((resolve, rejectPromise) => {
      axios.get('/care_history', {
        params: {
          patientIds: [patientId],
          filters: ['cares'],
          encounters: true,
          page,
        },
      })
        .then(({ data }) => resolve(data.events))
        .catch(() => rejectPromise())
    })
  },
  getExamRequests({ commit }, { patientId, ...params }) {
    commit('SET_LOADING_PATIENT_DATA', true)
    return new Promise((resolve, rejectPromise) => {
      axios.get(`/patient/${patientId}/exam_requests`, { params })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(rejectPromise)
        .finally(() => {
          commit('SET_LOADING_PATIENT_DATA', false)
        })
    })
  },
  listPatient({ commit }, filters = {}) {
    const params = reject(either(isNil, isEmpty), filters)
    return new Promise((resolve, rejectPromise) => {
      axios.get('/patient', { params })
        .then(({ data: patients }) => {
          commit('SET_PATIENTS', patients.patients)
          resolve(patients)
        })
        .catch(rejectPromise)
    })
  },
  async updatePatientSpecialConditions({ commit }, { id }) {
    commit('SET_LOADING_PATIENT_SPECIAL_CONDITIONS', true)

    return new Promise((resolve, rejectPromise) => {
      axios.get(`/patient/${id}/special_conditions`)
        .then(({ data }) => {
          const conditions = generateAppointmentExtendedCondition({ ...data })
          commit('SET_PATIENT_SPECIAL_CONDITIONS', conditions)
          commit('SET_PATIENT_CONDITIONS', data.patientConditions)
          commit(
            'SET_ACTIVATION_JOURNEY',
            !isEmpty(data.activationJourney)
              ? {
                ...data.activationJourney,
                careLinePatientId: data.activationJourneyCareLinePatientId,
              }
              : {},
          )
          commit('SET_JOURNEYS', data.journeys)
          resolve(conditions)
        })
        .catch(rejectPromise)
        .finally(() => {
          commit('SET_LOADING_PATIENT_SPECIAL_CONDITIONS', false)
        })
    })
  },
  updatedPatientSensitiveData(_, { payload, patientId }) {
    return new Promise((resolve, rejectPromise) => {
      axios.patch(`/patient/${patientId}/sensitive_data`, payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(rejectPromise)
    })
  },
  updatedPatientArchiveStatus(_, { payload, patientId }) {
    return new Promise((resolve, rejectPromise) => {
      axios.put(`/patient/archive/${patientId}`, payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(rejectPromise)
    })
  },
  async updatePatientData({ commit }, { patientId, patientData }) {
    commit('SET_LOADING_PATIENT_DATA', true)
    return axios.put(`patient/${patientId}`, patientData)
      .then(res => commit('SET_PATIENT_DATA', res.data))
      .catch(err => err)
      .finally(() => commit('SET_LOADING_PATIENT_DATA', false))
  },
  updateActivationJourney(_, { id, payload }) {
    return new Promise((resolve, rejectPromise) => {
      axios.put(`/emr/activation_journey/${id}`, payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(rejectPromise)
    })
  },
  shouldScheduleEngagementAppointment({ state }, { specialityId, appointmentType }) {
    const field = cond([
      [propEq(SPECIALITY.familyCommunityHealth, 'specialityId'), always('returnalAppointmentId')],
      [propEq(APPOINTMENT.types, 'appointmentType'.telemonitoring), always('telemonitoringAppointmentId')],
      [propEq(SPECIALITY.familyNurse, 'specialityId'), always('nurseAppointmentId')],
    ])({ specialityId, appointmentType })

    return Boolean(field
      && !isEmpty(state.activationJourney)
      && state.activationJourney.firstAppointmentId
      && !state.activationJourney[field])
  },
  async hasValidActivationJourneyAttributes({ state, dispatch, getters }, {
    specialityId,
    appointmentType,
    reschedule,
  }) {
    const isPresentialOrRemote = includes(appointmentType, [
      APPOINTMENT.types.presential,
      APPOINTMENT.types.remote,
    ])

    const rescheduleId = reschedule?.id
    const { activationJourney } = state

    const rescheduleFirstAppointment = activationJourney?.firstAppointmentId
      && activationJourney?.firstAppointmentId === rescheduleId

    if (getters.shouldScheduleFirstAppointment || rescheduleFirstAppointment) {
      return isPresentialOrRemote && equals(
        SPECIALITY.familyCommunityHealth,
        specialityId,
      )
    }

    const shouldScheduleEngagementAppointment = await dispatch('shouldScheduleEngagementAppointment', {
      specialityId,
      appointmentType,
    })

    const rescheduleTelemonitoring = activationJourney?.telemonitoringAppointmentId
      && equals(activationJourney?.telemonitoringAppointmentId, rescheduleId)

    if (equals(appointmentType, APPOINTMENT.types.telemonitoring)
      && (shouldScheduleEngagementAppointment || rescheduleTelemonitoring)
    ) return true

    const rescheduleNurse = activationJourney?.nurseAppointmentId
      && equals(activationJourney?.nurseAppointmentId, rescheduleId)

    if (equals(SPECIALITY.familyNurse, specialityId)
      && (shouldScheduleEngagementAppointment || rescheduleNurse)
    ) {
      return isPresentialOrRemote
    }

    const rescheduleReturnal = activationJourney?.returnalAppointmentId
      && equals(activationJourney?.returnalAppointmentId, rescheduleId)

    if (equals(SPECIALITY.familyCommunityHealth, specialityId)
      && (shouldScheduleEngagementAppointment || rescheduleReturnal)
    ) {
      return isPresentialOrRemote
    }

    return false
  },
  setRescheduleAppointment({ commit }, params) {
    return new Promise((resolve, rejectPromise) => {
      axios.get('appointment', {
        params,
      })
        .then(({ data }) => {
          const [appointment] = data.appointments
          if (appointment) {
            commit('SET_RESCHEDULE_APPOINTMENT', appointment)
          }
          resolve()
        })
        .catch(rejectPromise)
    })
  },
  clearReschedule({ commit }) {
    commit('SET_RESCHEDULE_APPOINTMENT', {})
  },
}

const getters = {
  isLoadingPatientData: state => state.loadingPatientData,
  patient: state => state.patient,
  patients: state => state.patients,
  isLoadingPatientSpecialConditions: state => state.loadingPatientSpecialConditions,
  patientSpecialConditions: state => state.patientSpecialConditions,
  shouldScheduleFirstAppointment: state => !isEmpty(state.activationJourney)
      && !state.activationJourney.firstAppointmentId
      && state.journeys.length > 0,
  shouldScheduleEngagementAppointment: state => state.activationJourney,
  isReschedulingFirstAppointment: state => Boolean(
    !isNil(state.reschedule)
      && !isNil(state.activationJourney?.firstAppointmentId)
      && state.reschedule.id === state.activationJourney?.firstAppointmentId,
  ),
  activationJourney: state => state.activationJourney,
  hasActivationJourney: state => !isEmpty(state.activationJourney)
    && state.journeys.length > 0,
  patientConditions: state => state.patientConditions,
  reschedule: state => state.reschedule,
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
