<template>
  <div>
    <div class="filters-container">
      <v-container
        :class="{
          'container--fluid': true,
          'px-12': $vuetify.breakpoint.smAndUp,
          'grid-list-sm': $vuetify.breakpoint.sm,
          'grid-list-md': $vuetify.breakpoint.md,
          'grid-list-lg': $vuetify.breakpoint.lgAndUp,
        }"
      >
        <v-row
          row
          wrap
        >
          <v-col
            class="px-1"
            cols="12"
            sm="6"
            md="2"
          >
            <vc-date-picker
              v-model="range"
              class="filters-date-picker"
              is-range
              locale="pt-PT"
              hide-details
              color="green"
            >
              <template v-slot="{ inputEvents }">
                <v-text-field
                  :value="dateRangeText"
                  readonly
                  hide-details
                  label="Data"
                  append-icon="mdi-calendar"
                  filled
                  dense
                  v-on="inputEvents.start"
                />
              </template>
            </vc-date-picker>
          </v-col>
          <v-col
            class="px-1"
            cols="12"
            sm="6"
            md="2"
          >
            <search-patient
              ref="patientInput"
              label="Paciente"
              hide-details
              clearable
              filled
              dense
              @input="updatePatientId($event)"
            />
          </v-col>
          <v-col
            class="px-1"
            cols="12"
            sm="6"
            md="2"
          >
            <v-autocomplete
              v-model="filters.healthMaintenanceOrganizationIds"
              hide-details
              :items="hmos"
              item-text="name"
              item-value="id"
              label="Operadora"
              no-data-text="Digite para buscar"
              clearable
              multiple
              filled
              dense
              hide-select
            />
          </v-col>
          <v-col
            class="px-1"
            cols="12"
            sm="6"
            md="2"
          >
            <v-autocomplete
              v-model="filters.healthProductIds"
              hide-details
              :items="healthProducts"
              item-text="name"
              item-value="id"
              label="Plano"
              no-data-text="Digite para buscar"
              clearable
              multiple
              filled
              dense
              hide-select
            />
          </v-col>
          <v-col
            class="px-1"
            cols="12"
            sm="6"
            md="2"
          >
            <v-autocomplete
              v-model="filters.clinicId"
              hide-details
              :items="clinics"
              multiple
              item-text="name"
              item-value="id"
              label="Unidade"
              no-data-text="Digite para buscar"
              append-icon=""
              clearable
              filled
              dense
            />
          </v-col>
          <v-col
            class="px-1"
            cols="12"
            sm="6"
            md="2"
          >
            <v-text-field
              v-model="filters.number"
              label="Número da OS"
              hide-details
              no-data-text="Digite para buscar"
              type="number"
              filled
              dense
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="px-1"
            cols="12"
            sm="6"
            md="2"
          >
            <v-autocomplete
              v-model="filters.isInvoiceEmitted"
              hide-details
              :items="invoiceFilterList"
              item-text="name"
              item-value="value"
              filled
              dense
              clearable
              label="Nota Fiscal"
              no-data-text="Digite para buscar"
              append-icon=""
            />
          </v-col>
          <v-col
            class="px-1 equalizing-md-sizes"
            sm="6"
            md="auto"
          >
            <v-autocomplete
              v-model="filters.status"
              hide-details
              :items="serviceStatusList"
              item-text="name"
              item-value="value"
              filled
              dense
              multiple
              clearable
              label="Status Da OS"
              append-icon=""
            />
          </v-col>
          <v-col
            class="px-1 equalizing-md-sizes"
            cols="12"
            sm="6"
            md="auto"
          >
            <v-autocomplete
              v-model="filters.professionalId"
              :items="professionalsItems"
              hide-details
              :search-input.sync="searchProfessional"
              label="Profissional"
              dense
              item-text="name"
              item-value="id"
              clearable
              filled
              multiple
              hide-select
              return-object
              no-data-text="Digite para buscar"
            />
          </v-col>
          <v-col
            class="px-1 equalizing-md-sizes"
            cols="12"
            sm="6"
            md="auto"
          >
            <v-autocomplete
              v-model="filters.specialityId"
              hide-details
              label="Especialidade"
              :items="specialities"
              item-text="name"
              item-value="id"
              dense
              clearable
              filled
              multiple
            />
          </v-col>
          <v-col
            class="px-1 equalizing-md-sizes"
            cols="12"
            sm="6"
            md="auto"
          >
            <v-select
              v-model="filters.appointmentStatus"
              hide-details
              label="Status do Agendamento"
              :items="appointmentStatus"
              item-text="label"
              item-value="value"
              clearable
              dense
              filled
              multiple
            />
          </v-col>
          <v-col
            class="px-1 equalizing-md-sizes"
            cols="12"
            sm="6"
            md="auto"
          >
            <v-select
              v-model="filters.hasReturnalRecommendation"
              hide-details
              label="Indicativo de Retorno"
              :items="hasReturnalRecommendationFilterList"
              item-text="name"
              item-value="value"
              clearable
              dense
              filled
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="px-1"
            cols="12"
            sm="6"
            md="2"
          >
            <v-text-field
              v-model="filters.hmoGuideNumber"
              label="Número da Guia"
              hide-details
              no-data-text="Digite para buscar"
              filled
              dense
              clearable
            />
          </v-col>

          <v-col
            class="px-1"
            cols="12"
            sm="6"
            md="2"
          >
            <v-text-field
              v-model="filters.authorizationCode"
              label="Autorização"
              hide-details
              no-data-text="Digite para buscar"
              filled
              dense
              clearable
            />
          </v-col>

          <v-col
            class="px-1"
            cols="12"
            sm="6"
            md="2"
          >
            <v-text-field
              v-model="filters.billNumber"
              label="Número da Fatura"
              hide-details
              no-data-text="Digite para buscar"
              type="number"
              filled
              dense
              clearable
            />
          </v-col>

          <v-col
            class="px-1 mb-4"
            cols="12"
            sm="6"
            md="4"
            offset-sm="0"
            offset-md="2"
          >
            <v-btn
              class="white-text font-weight-lightbold text-body-1"
              color="dark-green"
              block
              x-large
              @click="updateFilters"
            >
              <v-icon>
                mdi-magnify
              </v-icon>
              Filtrar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import {
  isNil,
  mapObjIndexed,
  join,
  values,
  map,
  isEmpty,
  toPairs,
  not,
  pluck,
  flatten,
} from 'ramda'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import { debounce } from 'lodash'
import SearchPatient from '@/components/Common/SearchPatient'
import { BILLING, APPOINTMENT } from 'amparo-enums'

export default {
  name: 'OrderFilters',
  components: {
    SearchPatient,
  },
  data: () => ({
    filters: {},
    range: {},
    isInvoiceEmitted: {
      true: 'Sim',
      false: 'Não',
    },
    hasReturnalRecommendationValues: {
      true: 'Sim',
      false: 'Não',
    },
    appointmentStatus: values(APPOINTMENT.status),
    searchProfessional: null,
    professionalsItems: [],
  }),
  computed: {
    ...mapGetters({
      clinics: 'clinic/clinics',
      hmos: 'healthMaintenanceOrganization/hmos',
      specialities: 'speciality/specialities',
      professionals: 'professional/professionals',
    }),
    healthProducts() {
      return flatten(pluck('healthProducts', this.hmos))
    },
    invoiceFilterList() {
      return this.getNamesAndValues(this.isInvoiceEmitted)
    },
    serviceStatusList() {
      return this.getNamesAndValues(BILLING.status)
    },
    hasReturnalRecommendationFilterList() {
      return this.getNamesAndValues(this.hasReturnalRecommendationValues)
    },
    dateRangeText() {
      return join(' a ')(values(this.formatedDatesToShow))
    },
    formatedDatesToShow() {
      return mapObjIndexed(
        (date) => {
          if (isNil(date)) return ''
          return moment(date).format('DD/MM/YYYY')
        },
        this.range,
      )
    },
    formatedDatesToSend() {
      return mapObjIndexed(
        date => moment(date).format('YYYY-MM-DD'),
        this.range,
      )
    },
  },
  watch: {
    searchProfessional: debounce(function searchProfessional(name) {
      if (not(name)) return
      this.listProfessional({ name })
    }, 600),
    professionals(professionals) {
      this.professionalsItems = [...professionals, ...this.filters.professionalId || []]
    },
  },
  mounted() {
    this.listClinic()
    this.listHmo()
    this.listProfessional()
    this.listSpeciality()
    this.range = {
      start: this.formattedStartFilterDate(),
      end: this.formattedEndFilterDate(),
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/setSnackbar',
      listClinic: 'clinic/listClinic',
      listHmo: 'healthMaintenanceOrganization/listHmo',
      setOrderFilters: 'billing/setOrderFilters',
      listOrder: 'billing/listOrder',
      getSummary: 'billing/getSummary',
      listSpeciality: 'speciality/listSpeciality',
      listProfessional: 'professional/listProfessional',
    }),
    async updateFilters() {
      if (!this.isValidDateRange()) {
        this.setSnackbar({
          status: 'error',
          message: 'Ops! Data inválida. Certifique-se de selecionar um período de até 1 ano a partir da data atual.',
        })
        return
      }

      if (isEmpty(this.filters.number)) this.filters.number = undefined
      await this.setOrderFilters({
        startDate: this.formatedDatesToSend.start,
        endDate: this.formatedDatesToSend.end,
        ...this.filters,
        professionalId: pluck('id', this.filters.professionalId || []),
      })
      try {
        await this.listOrder()
      } catch (err) {
        this.setSnackbar({
          status: 'error',
          message: 'Erro ao buscar por ordens de serviço!',
        })
        return
      }
      await this.getSummary()
    },
    getNamesAndValues(array) {
      return map(([key, value]) => ({ value: key, name: value }), toPairs(array))
    },
    updatePatientId(event) {
      if (event) {
        this.filters.patientId = event.id
        return
      }
      this.filters.patientId = undefined
    },
    formattedStartFilterDate() {
      return moment().subtract(1, 'months').toDate()
    },
    formattedEndFilterDate() {
      return moment().toDate()
    },
    isDateWithinOneYearRange() {
      const oneYearAgo = moment().subtract(1, 'years').format('YYYY-MM-DD')
      const oneYearAhead = moment().add(1, 'years').format('YYYY-MM-DD')

      return (
        moment(this.range.start).isBetween(oneYearAgo, oneYearAhead, undefined, '[]')
        && moment(this.range.end).isBetween(oneYearAgo, oneYearAhead, undefined, '[]')
      )
    },
    isValidDateRange() {
      if (isNil(this.range)) return false

      const isDateDifferenceLessThanOneYear = moment(this.range.end).diff(this.range.start, 'years', true) <= 1

      return (
        this.isDateWithinOneYearRange()
        && isDateDifferenceLessThanOneYear
      )
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/colors.styl'
.filters-date-picker
  width 100%
.filters-container
  div
    padding-bottom 0 !important
.equalizing-md-sizes
  @media only screen and (min-width: 960px)
    flex 1 0 0 !important
</style>
