<template>
  <div>
    <div class="header-info lighter-green">
      <v-container
        fluid
        fill-height
      >
        <v-layout
          align-center
          fill-height
          ml-2
          row
        >
          <span class="header-info__items font-weight-medium">{{ clinic.name }}</span>
          <span class="header-info__items font-weight-medium">{{ speciality.name }}</span>
          <span class="header-info__items font-weight-medium">{{ professional.name }}</span>
        </v-layout>
      </v-container>
    </div>
    <v-container
      fluid
      grid-list-xl
    >
      <h2 class="info-text font-weight-lighter">
        Defina as configurações da restrição
      </h2>
      <v-layout
        row
        wrap
      >
        <v-flex sm12>
          <v-alert
            :value="hasAppointment"
            type="warning"
            width="100%"
            dense
            outlined
          >
            Existem agendamentos para a data selecionada
          </v-alert>
        </v-flex>
        <v-flex sm3>
          <v-menu
            v-model="menuStartDay"
            :close-on-content-click="false"
            :nudge-right="40"
            :disabled="isEditing"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="formatedStartDate"
                label="Data inicial"
                append-icon="mdi-calendar"
                filled
                readonly
                :disabled="isEditing"
                :error="$v.startDate.$error"
                @blur="$v.startDate.$touch()"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="startDate"
              locale="pt-br"
              no-title
              scrollable
              @input="menu = false"
            />
          </v-menu>
        </v-flex>
        <v-flex sm3>
          <v-menu
            v-model="menuEndDay"
            :close-on-content-click="false"
            :nudge-right="40"
            :disabled="isEditing"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="formatedEndDate"
                label="Data final"
                append-icon="mdi-calendar"
                hint="Em branco para infinito"
                persistent-hint
                filled
                readonly
                clearable
                :disabled="isEditing"
                :error="$v.endDate.$error"
                @blur="$v.endDate.$touch()"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="endDate"
              locale="pt-br"
              no-title
              scrollable
              @input="menu = false"
            />
          </v-menu>
        </v-flex>
        <v-flex sm2>
          <v-text-field
            v-if="isEditing"
            v-model="startTime"
            label="Hora início"
            filled
            disabled
          />
          <v-select
            v-else
            v-model="startTime"
            :items="startWorkHours"
            label="Hora início"
            filled
            :error="$v.startTime.$error"
            @focus="buildstartWorkHoursList"
            @blur="$v.startTime.$touch()"
          />
        </v-flex>
        <v-flex sm2>
          <v-select
            v-model="slotInterval"
            :items="slotIntervalItems"
            label="Intervalo de slots"
            filled
            :disabled="isEditing"
            :error="$v.slotInterval.$error"
            @blur="$v.slotInterval.$touch()"
          >
            <template v-slot:append>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    class="interval-tooltip"
                    v-on="on"
                  >
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  Define o intervalo de slots em que aparecerão as restrições
                </span>
              </v-tooltip>
            </template>
          </v-select>
        </v-flex>
        <v-flex sm2>
          <v-text-field
            v-if="isEditing"
            v-model="endTime"
            label="Hora fim"
            filled
            disabled
          />
          <v-select
            v-else
            v-model="endTime"
            :items="buildEndWorkHourList"
            label="Hora fim"
            no-data-text="Preencha os campos anteriores"
            filled
            :disabled="isEditing"
            :error="$v.endTime.$error"
            @blur="$v.endTime.$touch()"
          />
        </v-flex>
        <v-flex
          xs6
          class="column"
        >
          <v-autocomplete
            v-model="appointmentType"
            :items="listAppointmentTypes"
            label="Modalidade de consulta permitida"
            no-data-text="Digite para buscar"
            clearable
            filled
            multiple
          >
            <template #selection="{ index }">
              <v-flex v-if="index === 0">
                <span
                  v-if="shouldShowAllSelectedItems(appointmentType, listAppointmentTypes)"
                >
                  Todas
                </span>
                <span v-else>
                  {{ handleAppointmentTypeAutocompleteText(appointmentType, listAppointmentTypes) }}
                </span>
              </v-flex>
            </template>
            <template
              #prepend-item
            >
              <v-flex justify-start>
                <v-btn
                  depressed
                  small
                  color="primary"
                  @click="handleSelectAllAppointmentTypeValues()"
                >
                  Todos
                </v-btn>
                <v-btn
                  depressed
                  small
                  @click="appointmentType = []"
                >
                  Nenhum
                </v-btn>
              </v-flex>
            </template>
          </v-autocomplete>
        </v-flex>
        <v-flex
          xs6
          class="column"
        >
          <v-autocomplete
            v-model="hmos"
            :items="listHmos"
            return-object
            item-text="name"
            label="Planos de Saúde permitidos"
            no-data-text="Digite para buscar"
            clearable
            filled
            multiple
          >
            <template #selection="{ index }">
              <v-flex v-if="index === 0">
                <span
                  v-if="shouldShowAllSelectedItems(listHmos, hmos)"
                >
                  Todas
                </span>
                <span v-else>
                  {{ handleAutocompleteText(hmos, listHmos) }}
                </span>
              </v-flex>
            </template>
            <template
              #prepend-item
            >
              <v-flex justify-start>
                <v-btn
                  depressed
                  small
                  color="primary"
                  @click="handleSelectAllHmoValues()"
                >
                  Todos
                </v-btn>
                <v-btn
                  depressed
                  small
                  @click="hmos = []"
                >
                  Nenhum
                </v-btn>
              </v-flex>
            </template>
          </v-autocomplete>
        </v-flex>
        <v-flex
          xs6
          column
        >
          <v-autocomplete
            v-model="userProfessions"
            :items="listProfessions"
            return-object
            item-text="name"
            label="Permitir agendamento pela profissão"
            no-data-text="Digite para buscar"
            clearable
            filled
            multiple
          >
            <template #selection="{ index }">
              <v-flex v-if="index === 0">
                <span
                  v-if="shouldShowAllSelectedItems(listProfessions, userProfessions)"
                >
                  Todas
                </span>
                <span v-else>
                  {{ handleAutocompleteText(userProfessions, listProfessions) }}
                </span>
              </v-flex>
            </template>
            <template
              #prepend-item
            >
              <v-flex justify-start>
                <v-btn
                  depressed
                  small
                  color="primary"
                  @click="handleSelectAllProfessionValues()"
                >
                  Todos
                </v-btn>
                <v-btn
                  depressed
                  small
                  @click="userProfessions = []"
                >
                  Nenhum
                </v-btn>
              </v-flex>
            </template>
          </v-autocomplete>
        </v-flex>
        <v-flex
          xs6
          class="column"
        >
          <span>Permitir agendamento a partir do Meu Amparo</span>
          <v-radio-group
            v-model="scheduleByPatient"
            row
            dense
            mandatory
          >
            <v-radio
              color="black"
              label="Sim"
              :value="true"
            />
            <v-radio
              color="black"
              label="Não"
              :value="false"
            />
          </v-radio-group>
        </v-flex>
        <v-flex
          xs6
          class="column"
        >
          <span>Permitir agendamento pelo profissional da agenda</span>
          <v-radio-group
            v-model="scheduleByOwner"
            row
            dense
            mandatory
          >
            <v-radio
              color="black"
              label="Sim"
              :value="true"
            />
            <v-radio
              color="black"
              label="Não"
              :value="false"
            />
          </v-radio-group>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex
          md2
          class="cancel-button--fixed"
        >
          <v-btn
            color="primary"
            class="font-weight-bold"
            text
            large
            block
            @click="closeRestrictionDialog()"
          >
            Cancelar
          </v-btn>
        </v-flex>
        <v-flex
          md2
          class="save-button--fixed"
        >
          <v-btn
            color="primary"
            class="font-weight-bold"
            large
            block
            :loading="isLoading"
            @click="saveAgendaRestrictionPattern()"
          >
            Salvar
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import moment from 'moment'
import {
  isNil,
  isEmpty,
  map,
  equals,
  length,
  find,
  reduce,
  addIndex,
  intersection,
} from 'ramda'
import {
  mapActions,
  mapGetters,
} from 'vuex'
import { required } from 'vuelidate/lib/validators'
import AgendaRegisterMixin from '@/mixins/AgendaRegisterMixin'
import { APPOINTMENT } from 'amparo-enums'

export default {
  mixins: [AgendaRegisterMixin],
  props: ['clinic', 'speciality', 'professional', 'edit'],
  data() {
    return {
      slotIntervalItems: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
      ],
      appointmentType: [],
      userProfessions: [],
      hmos: [],
      hasAppointment: false,
      scheduleByPatient: true,
      scheduleByOwner: true,
      isLoading: false,
    }
  },
  validations() {
    if (this.isEditing) {
      return {
        startDate: {},
        endDate: {},
        startTime: {},
        endTime: {},
        slotInterval: {},
      }
    }

    const agendaPatternValidations = {
      startDate: {
        required,
        isSameOrAfterToday: () => this.isSameOrAfterToday(),
      },
      endDate: {
        isSameOrAfterStartDate: () => this.isSameOrAfterStartDate(),
      },
      startTime: { required },
      endTime: { required },
      slotInterval: { required },
    }
    return { ...agendaPatternValidations }
  },
  computed: {
    ...mapGetters({
      listHmos: 'healthMaintenanceOrganization/hmos',
      listProfessions: 'profession/professions',
    }),
    listAppointmentTypes() {
      return [
        APPOINTMENT.types.presential,
        APPOINTMENT.types.remote,
        APPOINTMENT.types.spontaneous,
        APPOINTMENT.types.procedure,
        APPOINTMENT.types.telemonitoring,
        APPOINTMENT.types.administrativeDemand,
      ]
    },
    isEditing() {
      return !isNil(this.edit)
    },
  },
  watch: {
    startDate() {
      this.findAppointments()
    },
    endDate() {
      this.findAppointments()
    },
  },
  mounted() {
    this.handleSelectAllHmoValues()
    this.handleSelectAllAppointmentTypeValues()
    this.handleSelectAllProfessionValues()
    if (this.isEditing) this.fillRestrictionToEdit()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/setSnackbar',
      updateRestriction: 'agenda/updateRestriction',
      createRestriction: 'agenda/createRestriction',
      listAppointment: 'agenda/listAppointment',
    }),
    async findAppointments() {
      if (isNil(this.startDate) && isNil(this.endDate)) return
      this.hasAppointment = !isEmpty(
        await this.listAppointment({
          speciality: this.speciality,
          clinic: this.clinic,
          professional: this.professional,
          startDate: moment(this.startDate).format('YYYY-MM-DD'),
          endDate: this.endDate ? moment(this.endDate).format('YYYY-MM-DD') : null,
        }),
      )
    },
    shouldShowAllSelectedItems(selectedItems, selectedItemsList) {
      return equals(length(selectedItems), length(selectedItemsList))
    },
    handleAppointmentTypeAutocompleteText(valuesList, namesObjectList) {
      if (length(valuesList) <= 5) {
        const reduceIndexed = addIndex(reduce)
        return reduceIndexed((acc, currItem, index) => {
          const itemSelected = find(item => (
            item === currItem
          ), namesObjectList)
          if (index === 0) {
            return itemSelected
          }
          return `${acc}, ${itemSelected}`
        }, '', valuesList)
      }
      return `${length(valuesList)} selecionados`
    },
    handleAutocompleteText(valuesList, namesObjectList) {
      if (length(valuesList) <= 5) {
        const reduceIndexed = addIndex(reduce)
        return reduceIndexed((acc, currItem, index) => {
          const itemSelected = find(item => (
            item.id === currItem.id
          ), namesObjectList)
          if (index === 0) {
            return itemSelected.name
          }
          return `${acc}, ${itemSelected.name}`
        }, '', valuesList)
      }
      return `${length(valuesList)} selecionados`
    },
    handleSelectAllHmoValues() {
      this.hmos = this.listHmos
    },
    handleSelectAllAppointmentTypeValues() {
      this.appointmentType = this.listAppointmentTypes
    },
    handleSelectAllProfessionValues() {
      this.userProfessions = this.listProfessions
    },
    fillRestrictionToEdit() {
      if (!isNil(this.edit)) {
        const {
          startDate,
          endDate,
          appointmentType,
          userProfessions,
          scheduleByPatient,
          scheduleByOwner,
          interval,
          slotInterval,
          startTime,
          endTime,
          hmos,
        } = this.edit

        this.startDate = startDate
        this.endDate = endDate
        this.interval = interval
        this.slotInterval = slotInterval
        this.startTime = moment(startTime, 'HH:mm:ss').format('HH:mm')
        this.endTime = moment(endTime, 'HH:mm:ss').format('HH:mm')
        this.scheduleByPatient = scheduleByPatient
        this.scheduleByOwner = scheduleByOwner
        this.hmos = hmos === null ? this.listHmos : hmos
        this.userProfessions = userProfessions === null ? this.listProfessions : userProfessions
        this.appointmentType = appointmentType === null
          ? this.listAppointmentTypes
          : appointmentType
      }
    },
    isIncluded(value, list) {
      if (isNil(value)) return false
      return !isEmpty(
        intersection(value, list),
      )
    },
    getRestrictions() {
      this.$emit('getRestrictions')
    },
    closeRestrictionDialog() {
      this.resetFields()
      this.$emit('closeRestrictionDialog')
    },
    getFormattedItemValue(selectedItems, selectedItemsList) {
      const shouldMapValues = Boolean(selectedItems[0]?.id)

      if (this.shouldShowAllSelectedItems(selectedItems, selectedItemsList)) return null

      if (shouldMapValues) return map(({ id, name }) => ({ id, name }), selectedItems)

      return selectedItems
    },
    buildAgendaRestriction() {
      const restriction = {
        startDate: this.startDate,
        endDate: this.endDate,
        startTime: this.startTime,
        endTime: this.endTime || null,
        professionalId: this.professional.id,
        specialityId: this.speciality.id,
        clinicId: this.clinic.id,
        slotInterval: this.slotInterval,
        hmos: this.getFormattedItemValue(this.hmos, this.listHmos),
        userProfessions: this.getFormattedItemValue(this.userProfessions, this.listProfessions),
        appointmentType: this.getFormattedItemValue(
          this.appointmentType,
          this.listAppointmentTypes,
        ),
        scheduleByPatient: this.scheduleByPatient,
        scheduleByOwner: this.scheduleByOwner,
      }
      return restriction
    },
    saveAgendaRestrictionPattern: debounce(async function saveAgendaRestrictionPattern() {
      this.$v.$touch()
      if (this.$v.$error) return

      const restriction = this.buildAgendaRestriction()

      try {
        this.isLoading = true
        if (this.isEditing) {
          const {
            hmos,
            appointmentType,
            scheduleByOwner,
            scheduleByPatient,
            userProfessions,
          } = restriction
          await this.updateRestriction({
            id: this.edit.id,
            attributes: {
              hmos,
              appointmentType,
              userProfessions,
              scheduleByOwner,
              scheduleByPatient,
            },
          })
        }
        if (!this.isEditing) await this.createRestriction(restriction)

        this.getRestrictions()
        this.closeRestrictionDialog()
      } catch (error) {
        const status = 'error'
        const message = this.setErrorMessage(error.response.data)

        this.setSnackbar({ status, message })
      } finally {
        this.isLoading = false
      }
    }, 500),
  },
}
</script>

<style lang="stylus" scoped>
@import '../../../style/_core/utils/helpers.styl'

.header-info
  height 120px

.header-info__items
  margin-right 95px
  font-size 20px
  letter-spacing 1px
  color rgba(0, 0, 0, 0.6)

.v-btn--text
  border 1px solid #ccc

.v-btn--large
  height 56px
  width 220px
  border-radius 8px
  letter-spacing 1px

@media only screen and (min-width: 601px)
  .cancel-button--fixed
    position fixed
    bottom 0
    right 130px

  .save-button--fixed
    position fixed
    bottom 0
    right 10px

</style>
