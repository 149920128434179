import Vue from 'vue'
import axios from '@/lib/axios'
import Vuetify from 'vuetify'
import Vuelidate from 'vuelidate'
import VueTheMask from 'vue-the-mask'
import VCalendar from 'v-calendar'
import App from '@/App'
import router from '@/router'
import store from '@/store/index'
import theme from '@/style/theme'
import pt from 'vuetify/es5/locale/pt'
import VCurrencyField from 'v-currency-field'
import Hotjar from 'vue-hotjar'
import VueGtag from 'vue-gtag'
import MarkdownEditor from 'v-markdown-editor'
import Maska from 'maska'
import * as Sentry from '@sentry/vue'
import { CaptureConsole } from '@sentry/integrations'
import { equals } from 'ramda'
import '@/style/tailwind.css'

import '@/filters'
import '@/directives'
import '@mdi/font/css/materialdesignicons.css'

import 'vuetify/dist/vuetify.min.css'
import 'v-markdown-editor/dist/v-markdown-editor.css'

const EventBus = new Vue()

Vue.config.productionTip = false

Vue.prototype.$filters = Vue.options.filters
Vue.prototype.$http = axios
Vue.prototype.$bus = EventBus

Vue.use(Maska)

Vue.use(Hotjar, {
  id: process.env.VUE_APP_HOTJAR_ID,
  isProduction: process.env.NODE_ENV === 'production',
})

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_ID },
}, router)

Vue.use(Vuetify)


Vue.use(VCurrencyField, {
  locale: 'pt-BR',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
})

Vue.use(Vuelidate)

Vue.use(VueTheMask)

Vue.use(VCalendar, {
  componentPrefix: 'vc',
})

Vue.use(MarkdownEditor)

const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme,
  lang: {
    locales: { pt },
    current: 'pt',
  },
})


if (equals('production', process.env.NODE_ENV)) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_DEPLOY_ENV,
    initialScope: {
      tags: { app: 'core.app' },
    },
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new CaptureConsole(),
    ],
    tracesSampleRate: 1.0,
  })
}

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
