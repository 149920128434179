import {
  includes,
  __,
  equals,
  all,
} from 'ramda'
import { WORK_SPACE } from 'amparo-enums'

export const MODULE = {
  contractVisualization: 'mod_clmko4wm30000sidq5q43ey4h',
  contractRevision: 'mod_cllr4me01000ohvdq2pw6g1ac',
  contractEdition: 'mod_cllr4me01000nhvdqbdln5dod',
  defaultEditBill: 'mod_cm0fb6fti0000bug38gxn24gy',
  adminEditBill: 'mod_cm0fb7y4t00001sg33xrkbpaj',
  financeEditBill: 'mod_cm0fb9nq20000tdg39n92acmz',
}

export const isWorkSpaceAmparo = equals(WORK_SPACE.amparoSaude)

export const isWorkSpaceSabin = equals(WORK_SPACE.sabin)

export const isWorkSpaceSabinColeta = equals(WORK_SPACE.sabinColeta)

export const hasAccessToRoute = (userAllowedRoutes, route, methods) => {
  if (!userAllowedRoutes[route]) return false

  return all(includes(__, userAllowedRoutes[route].methods))(methods)
}

export const hasAccessToFormSettings = (user, userAllowedRoutes) => hasAccessToRoute(
  userAllowedRoutes,
  '/emr/category',
  ['GET', 'POST', 'DELETE'],
) && hasAccessToRoute(
  userAllowedRoutes,
  '/emr/form',
  ['GET', 'POST', 'PATCH', 'DELETE'],
) && hasAccessToRoute(
  userAllowedRoutes,
  '/emr/form_question',
  ['GET', 'POST', 'PATCH', 'DELETE'],
) && hasAccessToRoute(
  userAllowedRoutes,
  '/emr/question',
  ['GET', 'POST', 'PUT', 'DELETE'],
) && isWorkSpaceAmparo(user.workSpaceId)

export const hasAccessToCommunicationSettings = (user, userAllowedRoutes) => hasAccessToRoute(
  userAllowedRoutes,
  '/emr/communication',
  ['GET', 'POST', 'PUT', 'DELETE'],
) && isWorkSpaceAmparo(user.workSpaceId)

export const hasAccessToCareLineAssign = (user, userAllowedRoutes) => hasAccessToRoute(
  userAllowedRoutes,
  '/emr/care_line_patient',
  ['GET', 'POST', 'PATCH', 'DELETE'],
) && hasAccessToRoute(
  userAllowedRoutes,
  '/emr/care_line_disease',
  ['GET', 'POST', 'DELETE'],
) && isWorkSpaceAmparo(user.workSpaceId)

export const hasAccessToModule = (userModules, module) => includes(module, userModules)

export default {
  isWorkSpaceAmparo,
  isWorkSpaceSabin,
  hasAccessToRoute,
  hasAccessToFormSettings,
  hasAccessToCommunicationSettings,
  hasAccessToCareLineAssign,
  hasAccessToModule,
  MODULE,
}
