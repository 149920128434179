import axios from '@/lib/axios'
import {
  append,
} from 'ramda'
import removeNilOrEmptyFromObject from '@/utils/removeNilOrEmptyFromObject'

const initialState = {
  loading: false,
  careTeams: [],
  count: 0,
  numberOfPages: 0,
}

const mutations = {
  SET_CARE_TEAMS(state, payload) {
    state.careTeams = payload
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_COUNT(state, payload) {
    state.count = payload
  },
  SET_NUMBER_OF_PAGES(state, payload) {
    state.numberOfPages = payload
  },
}

const actions = {
  createCareTeam({ commit }, attributes) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.post('care_team', attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  updateCareTeam({ commit }, { id, attributes }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.put(`care_team/${id}`, attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  updateCareTeamScores({ commit }, { careTeamIds }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.patch('care_team/scores/', { careTeamIds })
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  updateMaxScoreCareTeam({ commit }, { id, maxScore }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.put(`care_team/max_score/${id}`, { maxScore })
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  migratePatientsCareTeam({ commit }, { id, attributes }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.post(`care_team/migrate_patients/${id}`, removeNilOrEmptyFromObject(attributes))
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  listCareTeam({ commit }, filters = {}) {
    const params = removeNilOrEmptyFromObject(filters)

    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.get('care_team', { params })
        .then(({ data }) => {
          commit('SET_CARE_TEAMS', data)
          resolve(data)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  getPatientScoreSum(_, filters = {}) {
    const params = removeNilOrEmptyFromObject(filters)

    return new Promise((resolve, reject) => {
      axios.get('care_team/patient_score_sum', { params })
        .then(({ data }) => {
          // eslint-disable-next-line radix
          resolve(parseInt(data) / 100)
        })
        .catch(reject)
    })
  },
}

const getters = {
  careTeams: state => state.careTeams,
  careTeamsWithNone: state => append({
    name: 'Sem Equipe',
    id: '',
  }, state.careTeams),
  loading: state => state.loading,
  count: state => state.count,
  numberOfPages: state => state.numberOfPages,
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
