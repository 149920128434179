<template>
  <div>
    <div class="header-info lighter-green">
      <v-container
        fluid
        fill-height
      >
        <v-layout
          align-center
          fill-height
          ml-2
          row
        >
          <span class="header-info__items font-weight-medium">{{ clinic.name }}</span>
          <span class="header-info__items font-weight-medium">{{ speciality.name }}</span>
          <span class="header-info__items font-weight-medium">{{ professional.name }}</span>
        </v-layout>
      </v-container>
    </div>
    <v-container
      fluid
      grid-list-xl
    >
      <h2 class="info-text font-weight-lighter">
        Defina as configurações do bloqueio
      </h2>
      <v-layout
        row
        wrap
      >
        <v-flex sm3>
          <v-menu
            v-model="menuStartDay"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="formatedStartDate"
                label="Data inicial"
                append-icon="mdi-calendar"
                filled
                readonly
                :error="$v.startDate.$error"
                @blur="$v.startDate.$touch()"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="startDate"
              locale="pt-br"
              no-title
              scrollable
              @input="menu = false"
            />
          </v-menu>
        </v-flex>
        <v-flex sm3>
          <v-menu
            v-model="menuEndDay"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="formatedEndDate"
                label="Data final"
                append-icon="mdi-calendar"
                filled
                readonly
                clearable
                :error="$v.endDate.$error"
                @blur="$v.endDate.$touch()"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="endDate"
              locale="pt-br"
              no-title
              scrollable
              @input="menu = false"
            />
          </v-menu>
        </v-flex>
        <v-flex sm3>
          <v-select
            v-model="startTime"
            :items="startWorkHours"
            label="Hora início"
            filled
            :error="$v.startTime.$error"
            @focus="buildstartWorkHoursList"
            @blur="$v.startTime.$touch()"
          />
        </v-flex>
        <v-flex sm3>
          <v-select
            v-model="endTime"
            :items="buildEndWorkHourList"
            label="Hora fim"
            no-data-text="Preencha os campos anteriores"
            filled
            :error="$v.endTime.$error"
            @blur="$v.endTime.$touch()"
          />
        </v-flex>
        <v-flex>
          <v-select
            v-model="reason"
            :items="reasonItems"
            label="Razão"
            filled
            item-text="label"
            item-value="value"
            :error="$v.reason.$error"
            @blur="$v.reason.$touch()"
            @change="clearOtherReason"
          />
        </v-flex>
        <v-flex v-if="isOtherReasonSelected">
          <v-text-field
            v-model="otherReason"
            label="Descreva a razão"
            filled
            counter
            maxlength="50"
            :error="$v.otherReason.$error"
            @blur="$v.otherReason.$touch()"
          />
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex mb-2>
          <v-checkbox
            v-model="isPaid"
            class="mt-0"
            label="Bloqueio Remunerado"
            color="green"
          />
        </v-flex>
        <v-flex
          md2
          class="cancel-button"
        >
          <v-btn
            color="primary"
            class="mr-3 font-weight-bold"
            text
            large
            block
            @click="handleCloseBlockDialog()"
          >
            Cancelar
          </v-btn>
        </v-flex>
        <v-flex
          md2
          class="save-button"
        >
          <v-btn
            color="primary"
            class="font-weight-bold"
            large
            block
            :loading="isLoading"
            @click="saveAgendaBlockPattern()"
          >
            Salvar
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import moment from 'moment'
import AgendaRegisterMixin from '@/mixins/AgendaRegisterMixin'
import { required, maxLength, requiredIf } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import { equals, omit, values } from 'ramda'
import { BLOCK } from 'amparo-enums'
import isNilOrEmpty from '@/utils/dataValidators'

export default {
  mixins: [AgendaRegisterMixin],
  props: ['clinic', 'speciality', 'professional', 'editData'],
  data() {
    return {
      reasonItems: values(BLOCK.reasons),
      isPaid: false,
      isLoading: false,
    }
  },
  validations() {
    const agendaPatternValidations = {
      startDate: {
        required,
      },
      endDate: {
        required,
        isSameOrAfterStartDate: () => this.isSameOrAfterStartDate(),
      },
      startTime: { required },
      endTime: { required },
      interval: { required },
      reason: { required, maxLength: maxLength(50) },
      otherReason: { requiredIf: requiredIf(() => this.isOtherReasonSelected) },
    }
    return { ...agendaPatternValidations }
  },
  computed: {
    isOtherReasonSelected() {
      return equals(this.reason, BLOCK.reasons.other.value)
    },
    isEditing() {
      return !isNilOrEmpty(this.editData)
    },
  },
  mounted() {
    this.buildstartWorkHoursList()
    if (this.isEditing) this.fillBlockToEdit()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/setSnackbar',
      createBlock: 'agenda/createBlock',
      updateBlock: 'agenda/updateBlock',
    }),
    fillBlockToEdit() {
      const {
        startDate,
        endDate,
        startTime,
        endTime,
        reason,
        isPaid,
        otherReason,
      } = this.editData

      this.startDate = moment(startDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
      this.endDate = moment(endDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
      this.startTime = moment(startTime, 'HH:mm:ss').format('HH:mm')
      this.endTime = moment(endTime, 'HH:mm:ss').format('HH:mm')
      this.reason = reason
      this.otherReason = otherReason
      this.isPaid = isPaid
    },
    clearOtherReason() {
      this.otherReason = null
    },
    getAgendaBlocks() {
      this.$emit('getAgendaBlocks')
    },
    handleCloseBlockDialog() {
      this.resetFields()
      this.$emit('closeBlockDialog')
    },
    buildAgendaBlock() {
      const agenda = {
        startDate: moment(this.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        endDate: moment(this.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        startTime: this.startTime,
        endTime: this.endTime,
        professionalId: this.professional.id,
        specialityId: this.speciality.id,
        clinicId: this.clinic.id,
        reason: this.reason,
        isPaid: this.isPaid,
      }
      if (this.isOtherReasonSelected) {
        agenda.otherReason = this.otherReason
      }

      return this.isEditing
        ? omit(['professionalId', 'specialityId', 'clinicId'], agenda)
        : agenda
    },
    saveAgendaBlockPattern: debounce(async function saveAgendaBlockPattern() {
      this.$v.$touch()
      if (this.$v.$error) return
      const block = this.buildAgendaBlock()
      try {
        if (this.isEditing) {
          await this.updateBlock({
            id: this.editData.id,
            attributes: block,
          })
        } else {
          await this.createBlock(block)
        }
        await this.getAgendaBlocks()
        await this.resetFields()
        await this.handleCloseBlockDialog()
      } catch (error) {
        const status = 'error'
        const message = this.setErrorMessage(error.response.data)
        this.setSnackbar({ status, message })
      } finally {
        this.isLoading = false
      }
    }, 500),
  },
}
</script>

<style lang="stylus" scoped>
@import '../../../style/_core/utils/helpers.styl'

.header-info
  height 120px

.header-info__items
  margin-right 95px
  font-size 20px
  letter-spacing 1px
  color rgba(0, 0, 0, 0.6)

.v-btn--text
  border 1px solid #ccc

.v-btn--large
  height 56px
  width 220px
  border-radius 8px
  letter-spacing 1px

@media only screen and (min-width: 601px)
  .cancel-button
    position fixed
    bottom 0
    right 130px

  .save-button
    position fixed
    bottom 0
    right 10px

</style>
