<template>
  <div>
    <loading-patient-editor v-if="patientId && !loadedPatient" />
    <v-toolbar
      v-else-if="patientId"
      color="primary"
      dark
    >
      <v-row
        justify="space-between"
        align="center"
      >
        <v-col class="font-weight-medium title-h3 white--text">
          Editar Paciente
        </v-col>
        <v-col cols="auto">
          <v-btn
            icon
            dark
            @click="closeEditDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-container
      v-if="loadedPatient"
      class="pa-8 create-patient__section"
      fluid
    >
      <v-row>
        <v-col
          cols="12"
          class="title-h3 mb-2"
        >
          Operadora
        </v-col>
        <v-col cols="2">
          <v-autocomplete
            v-model="patient.healthMaintenanceOrganization"
            data-testid="patient__health-maintenance-organization--select"
            label="Operadora"
            item-text="name"
            item-value="id"
            filled
            return-object
            :items="allowedHmos"
            :error-messages="getErrorMessage(['patient', 'healthMaintenanceOrganization'])"
            :error="$v.patient.healthMaintenanceOrganization.$error"
            @blur="$v.patient.healthMaintenanceOrganization.$touch()"
            @change="resetPatientHmoData"
          />
        </v-col>
        <v-col cols="2">
          <v-autocomplete
            v-model="patient.healthProductId"
            data-testid="patient__health-product--select"
            label="Plano"
            item-text="name"
            item-value="id"
            filled
            :items="filteredHealthProducts"
            :error-messages="getErrorMessage(['patient', 'healthProductId'])"
            :error="$v.patient.healthProductId.$error"
            @blur="$v.patient.healthProductId.$touch()"
          />
        </v-col>
        <v-col
          v-if="hmoHasProduct"
          cols="2"
        >
          <v-text-field
            v-model="patient.healthInsurancePlanProductCode"
            label="Produto"
            clearable
            filled
            persistent-hint
            :counter="healthInsuranceProductNumberLength"
            :error-messages="getErrorMessage(['patient', 'healthInsurancePlanProductCode'])"
            :error="$v.patient.healthInsurancePlanProductCode.$error"
            @blur="$v.patient.healthInsurancePlanProductCode.$touch()"
          />
        </v-col>
        <v-col v-if="!isAmparoOrAirportHmo">
          <custom-text-field
            v-model="patient.healthInsurancePlanNumber"
            data-testid="patient__health-insurance-plan-number--field"
            label="Número da carteirinha"
            clearable
            persistent-hint
            filled
            :hint="hmoEligibility.error"
            :counter="healthInsurancePlanNumberLength"
            :warn-message="hmoEligibility.error"
            :error="$v.patient.healthInsurancePlanNumber.$error"
            :error-messages="getErrorMessage(['patient', 'healthInsurancePlanNumber'])"
            :success-messages="hmoEligibility.success"
            @blur="$v.patient.healthInsurancePlanNumber.$touch()"
          />
        </v-col>
        <v-col v-if="!isAmparoOrAirportHmo">
          <v-text-field
            v-model="patient.healthInsurancePlanExpiration"
            v-maska="masks.healthInsurancePlanExpiration"
            class="optional-field"
            label="Validade"
            hint="MM/AAAA - Opcional"
            filled
            clearable
            persistent-hint
            :error-messages="getErrorMessage(['patient', 'healthInsurancePlanExpiration'])"
            :error="$v.patient.healthInsurancePlanExpiration.$error"
            @blur="$v.patient.healthInsurancePlanExpiration.$touch()"
          />
        </v-col>
        <v-col v-if="shouldShowManualEligibility">
          <v-select
            v-model="manualEligibility"
            label="Status elegibilidade"
            item-text="title"
            item-value="value"
            filled
            :items="formOptions.manualEligibility"
            @input="setEligibility($event, 'manual')"
          />
        </v-col>
        <v-col
          v-if="selectedHmo.hasEligibilityCheck"
          cols="auto"
        >
          <check-eligibility-button
            icon-button
            :patient="patient"
            @eligibility="setEligibility($event, 'automatic')"
          />
        </v-col>
      </v-row>
      <v-row v-if="!isAmparoOrAirportHmo">
        <v-col cols="2">
          <v-select
            v-model="isOwnerOfHmo"
            data-testid="hmo-owner-select"
            label="É titular do plano?"
            item-text="title"
            item-value="value"
            filled
            :items="formOptions.yesOrNo"
            :disabled="!hmoAllowOwner"
            @change="resetOwnerOfHmoData"
          />
        </v-col>
        <v-col cols="6">
          <search-patient
            v-model="selectedOwnerOfHmo"
            v-default-patient="selectedOwnerOfHmo"
            label="Titular"
            clearable
            filled
            :error="$v.selectedOwnerOfHmo.$error"
            :error-messages="getErrorMessage(['selectedOwnerOfHmo'])"
            :disabled="!hmoAllowOwner || isOwnerOfHmo"
            @blur="$v.selectedOwnerOfHmo.$touch()"
          />
        </v-col>
        <v-col cols="4">
          <v-btn
            data-testid="create-patient__register-responsible-button"
            class="btn font-weight-bold"
            color="primary"
            height="56px"
            large
            block
            :disabled="shouldDisableOwnerRegister"
            @click.stop="setResponsibleOrOwnerDialog('owner', true)"
          >
            Cadastrar titular
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="showMedicalFollowUp">
        <v-col
          class="title-h3 mb-2"
          cols="12"
        >
          Termo de aceite para acompanhamento
        </v-col>
        <v-col
          cols="3"
          class="title-h4"
        >
          Paciente deseja ter acompanhamento clínico pela amparo
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="patient.medicalFollowUpStatus"
            data-testid="patient__following__terms-accepted--select"
            label="Resposta"
            filled
            :items="formOptions.medicalFollowUpStatus"
            :error-messages="getErrorMessage(['patient', 'medicalFollowUpStatus'])"
            :error="$v.patient.medicalFollowUpStatus.$error"
            @blur="$v.patient.medicalFollowUpStatus.$touch()"
            @change="resetMedicalFollowUpData"
          />
        </v-col>
        <v-col v-if="followUpIsRefused">
          <v-text-field
            v-model="patient.medicalFollowUpRefuseReason"
            label="Motivo da recusa"
            clearable
            filled
            :counter="maxFollowUpRefuseReasonSize"
            :error-messages="getErrorMessage(['patient', 'medicalFollowUpRefuseReason'])"
            :error="$v.patient.medicalFollowUpRefuseReason.$error"
            @blur="$v.patient.medicalFollowUpRefuseReason.$touch()"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="title-h3 mb-2"
          cols="12"
        >
          Dados pessoais
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="hasResponsible"
            label="Possui responsável?"
            item-text="title"
            item-value="value"
            filled
            :items="formOptions.yesOrNo"
            @change="resetResponsibleData"
          />
        </v-col>
        <v-col cols="4">
          <search-patient
            v-model="selectedResponsible"
            v-default-patient="selectedResponsible"
            label="Responsável"
            clearable
            filled
            :error="$v.selectedResponsible.$error"
            :error-messages="getErrorMessage(['selectedResponsible'])"
            :disabled="!hasResponsible"
            @blur="$v.selectedResponsible.$touch()"
          />
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="patient.responsibleRelationship"
            label="Grau de parentesco"
            filled
            :disabled="!hasResponsible"
            :items="formOptions.responsibleRelationshipItems"
            :error-messages="getErrorMessage(['patient', 'responsibleRelationship'])"
            :error="$v.patient.responsibleRelationship.$error"
            @blur="$v.patient.responsibleRelationship.$touch()"
          />
        </v-col>
        <v-col cols="4">
          <v-btn
            color="primary"
            class="btn font-weight-bold"
            height="56px"
            large
            block
            :disabled="shouldDisableResponsibleRegister"
            @click.stop="setResponsibleOrOwnerDialog('responsible', true)"
          >
            Cadastrar responsável
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="patient.name"
            data-testid="patient__name--field"
            label="Nome completo"
            clearable
            filled
            :error="$v.patient.name.$error"
            :error-messages="getErrorMessage(['patient', 'name'])"
            @blur="$v.patient.name.$touch()"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="patient.socialName"
            class="optional-field"
            label="Nome social"
            hint="Opcional"
            persistent-hint
            clearable
            filled
            :error-messages="getErrorMessage(['patient', 'socialName'])"
            :error="$v.patient.socialName.$error"
            @blur="$v.patient.socialName.$touch()"
          />
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="patient.isSpecialNeeds"
            data-testid="patient__special-needs--select"
            label="Possui necessidade especial?"
            item-text="title"
            item-value="value"
            filled
            :items="formOptions.yesOrNo"
            :error-messages="getErrorMessage(['patient', 'isSpecialNeeds'])"
            :error="$v.patient.isSpecialNeeds.$error"
            @blur="$v.patient.isSpecialNeeds.$touch()"
            @change="resetSpecialNeedsData"
          />
        </v-col>
        <v-col
          v-if="patient.isSpecialNeeds"
          cols="3"
        >
          <v-select
            v-model="patient.specialNeedsType"
            label="Qual tipo?"
            filled
            :error="$v.patient.specialNeedsType.$error"
            :error-messages="getErrorMessage(['patient', 'specialNeedsType'])"
            :items="formOptions.specialNeedsType"
            @blur="$v.patient.specialNeedsType.$touch()"
          />
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="documentType"
            data-testid="document-type-select"
            label="Tipo de documento"
            item-text="title"
            item-value="value"
            filled
            :items="formOptions.documentTypes"
          />
        </v-col>
        <v-col
          data-testid="patient__cpf--container"
          cols="3"
        >
          <v-text-field
            v-show="documentTypeIsCpf"
            v-maska="masks.cpf"
            data-testid="patient__cpf--field"
            label="CPF"
            filled
            :value="mask(patient.cpf, masks.cpf)"
            :error-messages="customDocumentErrorMessage || getErrorMessage(['patient', 'cpf'])"
            :error="$v.patient.cpf.$error"
            @blur="$v.patient.cpf.$touch()"
            @maska="patient.cpf = $event.target.dataset.maskRawValue"
          />
          <v-text-field
            v-show="!documentTypeIsCpf"
            v-model="patient.foreignDocument"
            data-testid="patient-foreign-document-field"
            label="Número do Passaporte"
            filled
            :error-messages="customDocumentErrorMessage
              || getErrorMessage(['patient', 'foreignDocument'])"
            :error="$v.patient.foreignDocument.$error"
            @blur="$v.patient.foreignDocument.$touch()"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="patient.rg"
            class="optional-field"
            label="RG"
            hint="Opcional"
            persistent-hint
            filled
            clearable
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="patient.birthDate"
            v-maska="masks.birthDate"
            data-testid="patient__birth-date--field"
            label="Data de nascimento"
            hint="DD/MM/AAAA"
            append-icon="mdi-calendar"
            clearable
            filled
            :error-messages="getErrorMessage(['patient', 'birthDate'])"
            :error="$v.patient.birthDate.$error"
            @blur="$v.patient.birthDate.$touch()"
          />
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="patient.biologicalSex"
            data-testid="patient__biological-sex--select"
            label="Sexo biológico"
            item-text="title"
            item-value="value"
            filled
            :error-messages="getErrorMessage(['patient', 'biologicalSex'])"
            :error="$v.patient.biologicalSex.$error"
            :items="formOptions.biologicalSex"
            @blur="$v.patient.biologicalSex.$touch()"
          />
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="patient.placeOfBirth"
            class="optional-field"
            label="Onde nasceu?"
            hint="Opcional"
            persistent-hint
            auto-select-first
            clearable
            filled
            :items="countriesOptions"
            :no-data-text="notFoundMessage"
          />
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="patient.isForeign"
            data-testid="patient__is-foreign--select"
            label="É estrangeiro?"
            item-text="title"
            item-value="value"
            filled
            :items="formOptions.yesOrNo"
            :error-messages="getErrorMessage(['patient', 'isForeign'])"
            :error="$v.patient.isForeign.$error"
            @blur="$v.patient.isForeign.$touch()"
          />
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="patient.ddiTelphone"
            label="DDI"
            persistent-hint
            filled
            :items="countryCodeOptions"
            :no-data-text="notFoundMessage"
            :error-messages="getErrorMessage(['patient', 'ddiTelphone'])"
            :error="$v.patient.ddiTelphone.$error"
            @blur="$v.patient.ddiTelphone.$touch()"
            @change="resetPhoneData({ isSecondaryPhone: false })"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="patient.telphone"
            v-maska="getMaskPhone(patient.ddiTelphone)"
            data-testid="patient__telphone--field"
            label="Telefone"
            clearable
            filled
            :error-messages="getErrorMessage(['patient', 'telphone'])"
            :error="$v.patient.telphone.$error"
            @blur="$v.patient.telphone.$touch()"
          />
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="patient.ddiTelphoneSecondary"
            label="DDI"
            persistent-hint
            filled
            :items="countryCodeOptions"
            :error-messages="getErrorMessage(['patient', 'ddiTelphoneSecondary'])"
            :error="$v.patient.ddiTelphoneSecondary.$error"
            @blur="$v.patient.ddiTelphoneSecondary.$touch()"
            @change="resetPhoneData({ isSecondaryPhone: true })"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="patient.telphoneSecondary"
            v-maska="getMaskPhone(patient.ddiTelphoneSecondary)"
            class="optional-field"
            label="Telefone adicional"
            persistent-hint
            hint="Opcional"
            clearable
            filled
            :error-messages="getErrorMessage(['patient', 'telphoneSecondary'])"
            :error="$v.patient.telphoneSecondary.$error"
            @blur="$v.patient.telphoneSecondary.$touch()"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="patient.email"
            data-testid="patient__email--field"
            label="E-mail"
            type="email"
            clearable
            filled
            :error-messages="getErrorMessage(['patient', 'email'])"
            :error="$v.patient.email.$error"
            @blur="$v.patient.email.$touch()"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="patient.emailSecondary"
            class="optional-field"
            label="E-mail adicional"
            type="email"
            hint="Opcional"
            persistent-hint
            clearable
            filled
            :error-messages="getErrorMessage(['patient', 'emailSecondary'])"
            :error="$v.patient.emailSecondary.$error"
            @blur="$v.patient.emailSecondary.$touch()"
          />
        </v-col>
        <v-col>
          <v-autocomplete
            v-model="patient.referralId"
            class="optional-field"
            label="Quem indicou?"
            item-text="name"
            item-value="id"
            hint="Opcional"
            persistent-hint
            auto-select-first
            clearable
            filled
            :items="referrals"
            :no-data-text="notFoundMessage"
            @change="resetReferralData"
          />
        </v-col>
        <v-col v-if="isReferralOther">
          <v-text-field
            v-model="patient.referralOther"
            label="Indicação"
            clearable
            filled
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-autocomplete
            v-model="patient.bloodType"
            class="optional-field"
            label="Tipo sanguíneo"
            hint="Opcional"
            persistent-hint
            filled
            :items="formOptions.bloodTypes"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="patient.profession"
            class="optional-field"
            label="Profissão"
            hint="Opcional"
            clearable
            filled
            persistent-hint
          />
        </v-col>
        <v-col>
          <v-select
            v-model="hasCompany"
            data-testid="patient__has-company--select"
            label="Pertence a uma empresa?"
            item-text="title"
            item-value="value"
            filled
            :items="formOptions.yesOrNo"
            :error-messages="getErrorMessage(['hasCompany'])"
            :error="$v.hasCompany.$error"
            @blur="$v.hasCompany.$touch()"
            @change="resetCompanyData"
          />
        </v-col>
        <v-col v-if="hasCompany">
          <v-text-field
            v-model="patient.company"
            label="Empresa"
            clearable
            filled
            :error-messages="getErrorMessage(['patient', 'company'])"
            :error="$v.patient.company.$error"
            @blur="$v.patient.company.$touch()"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="title-h3 mb-2"
          cols="12"
        >
          Endereço
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="patient.address.zipCode"
            v-maska="masks.zipCode"
            data-testid="patient__zip-code--field"
            class="optional-field"
            label="CEP"
            hint="Opcional"
            persistent-hint
            clearable
            filled
            :loading="isLoadingZipCode"
            :error="$v.patient.address.zipCode.$error"
            :error-messages="getErrorMessage(['patient', 'address', 'zipCode'])"
            @blur="$v.patient.address.zipCode.$touch()"
            @change="getZipCodeAddress(patient.address.zipCode)"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="patient.address.street"
            data-testid="patient__address--field"
            label="Logradouro"
            clearable
            filled
            :error-messages="getErrorMessage(['patient', 'address', 'street'])"
            :error="$v.patient.address.street.$error"
            @blur="$v.patient.address.street.$touch()"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="patient.address.number"
            data-testid="patient__address-number--field"
            label="Número"
            clearable
            filled
            :error-messages="getErrorMessage(['patient', 'address', 'number'])"
            :error="$v.patient.address.number.$error"
            @blur="$v.patient.address.number.$touch()"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="patient.address.complement"
            class="optional-field"
            label="Complemento"
            hint="Opcional"
            persistent-hint
            clearable
            filled
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="patient.address.neighborhood"
            data-testid="patient__neighborhood--field"
            label="Bairro"
            clearable
            filled
            :error-messages="getErrorMessage(['patient', 'address', 'neighborhood'])"
            :error="$v.patient.address.neighborhood.$error"
            @blur="$v.patient.address.neighborhood.$touch()"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="patient.address.city"
            data-testid="patient__city--field"
            label="Cidade"
            clearable
            filled
            :error-messages="getErrorMessage(['patient', 'address', 'city'])"
            :error="$v.patient.address.city.$error"
            @blur="$v.patient.address.city.$touch()"
          />
        </v-col>
        <v-col cols="4">
          <v-autocomplete
            v-model="patient.address.state"
            data-testid="patient__state--select"
            label="Estado"
            clearable
            filled
            :items="formOptions.states"
            :no-data-text="notFoundMessage"
            :error-messages="getErrorMessage(['patient', 'address', 'state'])"
            :error="$v.patient.address.state.$error"
            @blur="$v.patient.address.state.$touch()"
          />
        </v-col>
      </v-row>
      <v-row v-if="isWorkSpaceAmparo && !isNonePackageHmo">
        <v-col
          class="title-h3 mb-2"
          cols="12"
        >
          Equipe de Saúde
        </v-col>
        <v-col
          data-testid="patient__care-team--container"
          cols="12"
        >
          <v-autocomplete
            v-model="patient.careTeamId"
            data-testid="patient__care-team--select"
            label="Equipe de saúde"
            item-text="name"
            item-value="id"
            clearable
            filled
            :items="careTeams"
            :error="$v.patient.careTeamId.$error"
            :error-messages="getErrorMessage(['patient', 'careTeamId'])"
            @blur="$v.patient.careTeamId.$touch()"
          >
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item
                :disabled="item.score >= item.maxScore"
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-content>
                  <div>
                    {{ item.name }}
                    <span
                      v-if="item.score >= item.maxScore"
                      class="text-caption warning--text"
                    >
                      - (Limite de pontos da equipe atingido)
                    </span>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row
        v-if="isWorkSpaceAmparo"
      >
        <v-col cols="12">
          <v-textarea
            v-model="patient.notes"
            class="optional-field"
            label="Observações"
            rows="1"
            hint="Opcional"
            persistent-hint
            auto-grow
            filled
          />
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-col cols="auto">
          <v-btn
            data-testid="patient__save-patient--button"
            class="btn font-weight-bold"
            color="primary"
            :loading="isSaving"
            :disabled="isSaving"
            @click="savePatient()"
          >
            {{ saveButtonText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <create-patient-register-responsible-modal
      :show-dialog="responsibleDialog"
      :is-owner-register="isRegisteringOwner"
      @setResponsibleOrOwner="setResponsibleOrOwner"
      @closeDialog="setResponsibleOrOwnerDialog('both', false)"
    />
    <health-maintenance-organization-change-modal
      :show-dialog="showChangePlanDialog"
      :edited-patient="patient"
      :plan-number-owner="patient.healthInsurancePlanNumber"
      @update:show-dialog="setShowChangePlanDialog"
      @submit="updatePatient($event, true)"
    />
    <manual-eligibility-modal
      :show-dialog="showManualEligibilityDialog"
      @manualEligibilityUpdate="manualEligibilityUpdate"
      @update:show-dialog="setShowManualEligibilityDialog"
    />
  </div>
</template>

<script>
import moment from 'moment'
import cep from 'cep-promise'
import { isValid as isValidCPF } from '@fnando/cpf'
import {
  mapGetters,
  mapActions,
} from 'vuex'
import {
  includes,
  reject,
  path,
  not,
  propEq,
  isNil,
  equals,
  find,
  values,
  isEmpty,
  prop,
  map,
  has,
  head,
  last,
  keys,
  pickAll,
  filter,
  omit,
  cond,
  always,
  T,
  concat,
  all,
  __,
  pluck,
} from 'ramda'
import {
  HMO,
  ELIGIBILITY,
  SPECIALITY,
} from 'amparo-enums'
import {
  required,
  requiredIf,
  maxLength,
  minLength,
  email,
} from 'vuelidate/lib/validators'
import {
  dateFormat,
  cardDateFormat,
  validateDueDate,
  formatDateToISO8601,
} from '@/utils/dateFormat'
import { mask } from 'maska'
import isNilOrEmpty from '@/utils/dataValidators'
import { isDateAfter1900 } from '@/utils/dateValidators'
import InsuranceNumberValidations from '@/utils/healthInsurancePlanNumberValidations'
import onlyNumber from '@/utils/onlyNumber'
import countriesEnum from '@/enums/countries'
import REFERRAL from '@/enums/referral'
import patientEnuns from '@/enums/patient'

const {
  BIOLOGICAL_SEX,
  BLOOD_TYPES,
  DOCUMENT_TYPES,
  RESPONSIBLE_RELATIONSHIP,
  SPECIAL_NEEDS_TYPE,
  STATES,
  YES_OR_NO,
} = patientEnuns

export default {
  components: {
    CheckEligibilityButton: () => import('@/components/Common/CheckEligibilityButton'),
    SearchPatient: () => import('@/components/Common/SearchPatient'),
    CreatePatientRegisterResponsibleModal: () => import('./Modals/CreatePatientRegisterResponsibleModal'),
    HealthMaintenanceOrganizationChangeModal: () => import('./Modals/HealthMaintenanceOrganizationChangeModal'),
    LoadingPatientEditor: () => import('./LoadingPatientEditor'),
    ManualEligibilityModal: () => import('./Modals/ManualEligibilityModal'),
    CustomTextField: () => import('@/components/UI/CustomTextField'),
  },
  props: {
    patientId: {
      type: String,
      required: false,
      default: '',
    },
    virtualReceptionId: {
      type: String,
      required: false,
      default: '',
    },
    defaultValues: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      patient: {
        cpf: '',
        ddiTelphone: 55,
        address: {},
        isForeign: false,
        company: null,
      },
      hasCompany: null,
      isOwnerOfHmo: true,
      selectedOwnerOfHmo: null,
      hasResponsible: false,
      selectedResponsible: null,
      documentType: DOCUMENT_TYPES.CPF.value,
      hmoEligibility: {},
      manualEligibility: ELIGIBILITY.status.notEligible,
      showManualEligibilityDialog: false,
      showChangePlanDialog: false,
      responsibleDialog: false,
      isLoadingZipCode: false,
      isSaving: false,
      loadedPatient: true,
      isRegisteringOwner: false,
      brPhoneCode: 55,
      maxFollowUpRefuseReasonSize: 85,
      masks: {
        zipCode: '#####-###',
        cpf: '###.###.###-##',
        birthDate: '##/##/####',
        phone: ['(##) #####-####', '(##) ####-####'],
        healthInsurancePlanExpiration: '##/####',
      },
      formOptions: {
        manualEligibility: [
          {
            value: ELIGIBILITY.status.eligible,
            title: 'Validada',
          },
          {
            value: ELIGIBILITY.status.notEligible,
            title: 'Não válida',
          },
        ],
        medicalFollowUpStatus: [
          {
            text: 'Sim',
            value: 'acceptedApp',
          },
          {
            text: 'Não',
            value: 'refusedApp',
          },
        ],
        yesOrNo: values(YES_OR_NO),
        responsibleRelationshipItems: values(RESPONSIBLE_RELATIONSHIP),
        specialNeedsType: values(SPECIAL_NEEDS_TYPE),
        biologicalSex: values(BIOLOGICAL_SEX),
        bloodTypes: values(BLOOD_TYPES),
        states: values(STATES),
        documentTypes: values(DOCUMENT_TYPES),
      },
      customDocumentErrorMessage: null,
      notFoundMessage: 'Não encontrado',
    }
  },
  validations() {
    return {
      patient: {
        healthMaintenanceOrganization: { required },
        healthProductId: { required },
        healthInsurancePlanNumber: {
          required: requiredIf(() => not(this.isAmparoOrAirportHmo || this.isSabinHmo)),
          isValid: hmoNumber => InsuranceNumberValidations
            .isValidHealthInsurancePlanNumber(
              this.selectedHmo, hmoNumber,
            ),
        },
        healthInsurancePlanProductCode: {
          required: requiredIf(() => this.hmoHasProduct),
          isValid: productNumber => InsuranceNumberValidations
            .isValidProductCode(this.selectedHmo, productNumber),
        },
        healthInsurancePlanExpiration: {
          invalidDate: (date) => {
            if (!date) return true
            return cardDateFormat(date) && validateDueDate(date)
          },
        },
        company: {
          required: requiredIf(() => this.hasCompany),
        },
        medicalFollowUpStatus: {
          required: requiredIf(() => this.showMedicalFollowUp),
        },
        medicalFollowUpRefuseReason: {
          required: requiredIf(() => this.followUpIsRefused && this.showMedicalFollowUp),
          minLength: minLength(5),
          maxLength: maxLength(this.maxFollowUpRefuseReasonSize),
        },
        responsibleRelationship: {
          required: requiredIf(() => this.hasResponsible),
        },
        name: {
          required,
          minLength: minLength(2),
        },
        socialName: { minLength: minLength(2) },
        isSpecialNeeds: { required },
        specialNeedsType: {
          required: requiredIf(() => this.patient.isSpecialNeeds),
        },
        cpf: {
          required: requiredIf(() => this.documentTypeIsCpf),
          isValid: this.documentTypeIsCpf ? isValidCPF : true,
          isAlreadyRegistered: cpf => this.isDocumentAlreadyRegistered('cpf', cpf),
        },
        foreignDocument: {
          required: requiredIf(() => !this.documentTypeIsCpf),
          minLength: minLength(5),
          isAlreadyRegistered: foreignDocument => this
            .isDocumentAlreadyRegistered('foreignDocument', foreignDocument),
        },
        birthDate: {
          required,
          minLength: minLength(10),
          invalidDate: date => isDateAfter1900(date) && dateFormat(date),
        },
        biologicalSex: { required },
        isForeign: { required },
        ddiTelphone: { required },
        telphone: {
          required,
          minLength: minLength(9),
        },
        ddiTelphoneSecondary: {
          required: requiredIf(() => this.patient.telphoneSecondary),
        },
        telphoneSecondary: {
          isUnique: () => this.isUniqueValidator(
            this.patient.telphoneSecondary,
            this.patient.telphone,
            true,
          ),
          minLength: minLength(9),
        },
        email: {
          required,
          isValid: email,
        },
        emailSecondary: {
          isValid: email,
          isUnique: () => this.isUniqueValidator(
            this.patient.emailSecondary,
            this.patient.email,
          ),
        },
        address: {
          zipCode: {
            isValid: (value) => {
              if (!value) return true
              if (onlyNumber(value).length < 8) return false

              return new Promise((resolve) => {
                cep(value)
                  .then(() => resolve(true))
                  .catch(() => resolve(false))
              })
            },
          },
          street: {
            required: requiredIf(() => !this.patient.isForeign),
          },
          number: {
            required: requiredIf(() => !this.patient.isForeign),
          },
          neighborhood: {
            required: requiredIf(() => !this.patient.isForeign),
          },
          city: {
            required: requiredIf(() => !this.patient.isForeign),
          },
          state: {
            required: requiredIf(() => !this.patient.isForeign),
          },
        },
        careTeamId: {
          careTeamIsCompatibleWithHmo: () => this.careTeamIsCompatibleWithHmo(),
        },
      },
      isOwnerOfHmo: { required },
      selectedOwnerOfHmo: {
        required: requiredIf(() => !this.isOwnerOfHmo && this.hmoAllowOwner),
        ownerHmoInvalid: (owner) => {
          const patientHmoId = this.selectedHmo.id
          const ownerHmoId = path(['healthMaintenanceOrganization', 'id'], owner)

          if (includes(ownerHmoId, [HMO.sabinSulAmerica, HMO.ritaSaude])
            && includes(patientHmoId, [HMO.sabinSulAmerica, HMO.ritaSaude])
          ) return true

          if (isNil(owner) || isNil(patientHmoId)) return true

          if (!this.hmoAllowOwner) return true

          if (!equals(owner.healthMaintenanceOrganization.id, patientHmoId)) return false

          return true
        },
        sameOwnerAndPatient: (owner) => {
          if (isNil(owner)) return true
          if (equals(this.patient.id, owner.id)) return false
          return true
        },
      },
      hasResponsible: { required },
      hasCompany: { required },
      selectedResponsible: {
        required: requiredIf(() => this.hasResponsible),
      },
    }
  },
  computed: {
    ...mapGetters({
      referrals: 'referral/referrals',
      hmos: 'healthMaintenanceOrganization/hmos',
      isWorkSpaceAmparo: 'authentication/isWorkSpaceAmparo',
      userHasAccessToFunctionality: 'authentication/userHasAccessToFunctionality',
      hasActivationJourney: 'patient/hasActivationJourney',
    }),
    isNonePackageHmo() {
      return equals('none', this.selectedHmo?.packageType)
    },
    userHasAccessToCareTeam() {
      return this.userHasAccessToFunctionality.careTeam
    },
    showMedicalFollowUp() {
      return this.userHasAccessToFunctionality.medicalFollowUp
        && !this.isNonePackageHmo
    },
    selectedHmo() {
      return path(['healthMaintenanceOrganization'], this.patient) || {}
    },
    healthInsuranceProductNumberLength() {
      return path([
        'validationRules',
        'healthInsuranceProductNumberLength',
      ], this.selectedHmo)
    },
    healthInsurancePlanNumberLength() {
      return path([
        'validationRules',
        'healthInsurancePlanNumberLength',
      ], this.selectedHmo)
    },
    hmoAllowOwner() {
      if (isNil(this.selectedHmo.name)) return false
      return path(['allowOwner'], this.selectedHmo) || false
    },
    isAmparoOrAirportHmo() {
      if (isNil(this.selectedHmo.name)) return false
      const name = this.selectedHmo.name.toLowerCase()
      return includes('amparo', name)
        || includes('particular', name)
        || equals(this.selectedHmo.id, HMO.airport)
    },
    isSabinHmo() {
      if (isNil(this.selectedHmo.name)) return false
      const name = this.selectedHmo.name.toLowerCase()
      return includes('sabin', name)
    },
    allowedHmos() {
      if (not(includes(
        path(['healthMaintenanceOrganization', 'id'], this.patient),
        [HMO.postal],
      ))) {
        return reject(
          hmo => includes(hmo.id, [HMO.postal]),
          this.hmos,
        )
      }

      return this.hmos
    },
    filteredHealthProducts() {
      if (!this.hmos) return []
      const selectedHmoId = this.selectedHmo?.id
      if (isNil(selectedHmoId)) return []

      const patientHmo = find(
        propEq(selectedHmoId, 'id'),
        this.hmos,
      )
      if (isNil(patientHmo)) return []

      return patientHmo.healthProducts
    },
    hmoHasProduct() {
      return InsuranceNumberValidations.checkHmoHasProduct(this.selectedHmo)
    },
    countriesOptions() {
      return map(({ nome }) => nome, countriesEnum)
    },
    countryCodeOptions() {
      const countryCodeOptions = map(
        ({ fone, iso3 }) => ({
          value: Number(fone),
          text: `+${Number(fone)} ${iso3}`,
        }),
        countriesEnum,
      )
      return countryCodeOptions
    },
    shouldDisableOwnerRegister() {
      return !this.hmoAllowOwner
        || this.isOwnerOfHmo
        || !isNilOrEmpty(this.selectedOwnerOfHmo)
    },
    shouldDisableResponsibleRegister() {
      return !this.hasResponsible
        || !isNilOrEmpty(this.selectedResponsible)
    },
    shouldShowManualEligibility() {
      return (!isEmpty(this.hmoEligibility)
        && prop('status', this.hmoEligibility) !== ELIGIBILITY.status.success)
        || prop('manually', this.hmoEligibility)
    },
    followUpIsRefused() {
      return this.patient.medicalFollowUpStatus === 'refusedApp'
    },
    documentTypeIsCpf() {
      return equals(DOCUMENT_TYPES.CPF.value, this.documentType)
    },
    saveButtonText() {
      return this.patientId
        ? 'Salvar Alterações'
        : 'Cadastrar Paciente'
    },
    isReferralOther() {
      if (!this.patient.referralId) return false
      return this.patient.referralId === REFERRAL.OTHER_ID
    },
    mask() {
      return mask
    },
  },
  deactivated() {
    this.resetFields()
  },
  async mounted() {
    if (this.userHasAccessToCareTeam) {
      this.careTeams = await this.listCareTeam()
    }
    if (this.patientId) this.getPatientToEdit()
    this.setDefaultValues()
    this.listHmo()
    this.listReferral()
  },
  methods: {
    ...mapActions({
      listReferral: 'referral/listReferral',
      listCareTeam: 'careTeam/listCareTeam',
      listHmo: 'healthMaintenanceOrganization/listHmo',
      setSnackbar: 'snackbar/setSnackbar',
      updateEligibility: 'eligibility/updateEligibility',
      getActualContract: 'billing/getActualContract',
      updatePatientSpecialConditions: 'patient/updatePatientSpecialConditions',
    }),
    setDefaultValues() {
      if (!isNilOrEmpty(this.defaultValues)) {
        this.patient = {
          ...this.patient,
          ...this.defaultValues,
        }
      }
    },
    getPatientToEdit() {
      this.loadedPatient = false
      this.$http.get(`patient?id=${this.patientId}`)
        .then(({ data: { patients: [patient] } }) => {
          this.patient = patient

          this.oldPatient = patient
          if (this.hmoHasProduct) {
            this.patient = {
              ...this.patient,
              ...this.getHealthInsuranceNumbers(
                this.patient.healthInsurancePlanNumber,
                this.patient.healthMaintenanceOrganization.validationRules,
              ),
            }
          }

          if (isEmpty(this.patient.cpf)) {
            this.documentType = DOCUMENT_TYPES.FOREIGN_DOCUMENT.value
          }

          this.patient = {
            ...this.patient,
            ddiTelphone: Number(this.patient.ddiTelphone),
            ddiTelphoneSecondary: Number(this.patient.ddiTelphoneSecondary),
            birthDate: moment(this.patient.birthDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            careTeamId: path(['patient', 'careTeam', 'id'], this),
          }

          if (equals(this.patient.hmoId, this.patient.responsibleId)) {
            this.setResponsibleOrOwner({
              isOwner: true,
              isResponsible: true,
              id: this.patient.hmoOwnerId,
            })
            return
          }
          this.setResponsibleOrOwner({ isOwner: true, id: this.patient.hmoOwnerId })
          this.setResponsibleOrOwner({ isResponsible: true, id: this.patient.responsibleId })

          this.hasCompany = !isNil(this.patient.company)
        })
        .catch(() => this.setSnackbar({
          status: 'error',
          message: 'Não foi possível carregar paciente.',
        }))
        .finally(() => {
          this.loadedPatient = true
          this.$v.$touch()
        })
    },
    getHealthInsuranceNumbers(insurancePlanNumber, validationRules) {
      const hasValidPlanNumber = InsuranceNumberValidations.isValidInsurancePlanNumberLength(
        insurancePlanNumber, validationRules,
      )
      if (!hasValidPlanNumber) return {}

      const { healthInsuranceProductNumberLength } = validationRules

      return {
        healthInsurancePlanProductCode: insurancePlanNumber
          .substring(0, healthInsuranceProductNumberLength),
        healthInsurancePlanNumber: insurancePlanNumber
          .substring(healthInsuranceProductNumberLength),
      }
    },
    isDocumentAlreadyRegistered(documentName, document) {
      this.customDocumentErrorMessage = ''
      if (!document) return true
      return new Promise((resolve, rejectPromise) => {
        this.$http.get('patient', { params: { [documentName]: document } })
          .then(({ data }) => {
            const existingPatient = head(
              reject(prop('responsibleId'), data.patients),
            )

            if (isNilOrEmpty(existingPatient)) return resolve(true)

            const isSamePatient = equals(existingPatient.id, this.patient.id)
            const isSameDocumentFromResponsible = equals(
              this.selectedResponsible?.id,
              existingPatient.id,
            )

            if (isSamePatient || isSameDocumentFromResponsible) return resolve(true)

            const responsibleDocument = this.selectedResponsible?.cpf
              || this.selectedResponsible?.foreignNumber

            if (this.selectedResponsible && !equals(responsibleDocument, document)) {
              this.customDocumentErrorMessage = 'Aviso: Trocou o responsável'
            }

            return resolve(false)
          })
          .catch(() => rejectPromise())
      })
    },
    isUniqueValidator(field, compareField, isNumber = false) {
      if (!field) return true
      if (isNumber) return !equals(onlyNumber(field), onlyNumber(compareField))

      return !equals(field, compareField)
    },
    setResponsibleOrOwnerDialog(dialogType, value) {
      if (dialogType === 'owner') this.isRegisteringOwner = true
      if (!value) {
        this.responsibleDialog = false
        this.isRegisteringOwner = false
        return
      }
      this.responsibleDialog = true
    },
    setResponsibleOrOwner({ isOwner, isResponsible, id }) {
      if (isNil(id)) return
      this.$http.get(`patient/${id}`)
        .then(({ data: { patient } }) => {
          if (isResponsible) {
            this.hasResponsible = true
            this.selectedResponsible = patient
          }
          if (isOwner) {
            this.isOwnerOfHmo = false
            this.selectedOwnerOfHmo = patient
          }
        })
    },
    getMaskPhone(code) {
      return equals(code, this.brPhoneCode)
        ? this.masks.phone
        : '#*'
    },
    getZipCodeAddress(postalCode) {
      if (!postalCode) {
        this.resetAddress()
        return
      }
      if (onlyNumber(postalCode).length < 8) return

      this.isLoadingZipCode = true

      cep(postalCode)
        .then(({
          cep: zipCode,
          state,
          city,
          street,
          neighborhood,
        }) => {
          this.patient = {
            ...this.patient,
            address: {
              zipCode,
              state,
              city,
              street,
              neighborhood,
            },
          }
        })
        .catch(() => false)
        .finally(() => { this.isLoadingZipCode = false })
    },
    getPatientCareTeam() {
      const patientCareTeamId = this.patient.careTeamId

      if (patientCareTeamId) {
        return find(
          propEq(patientCareTeamId, 'id'),
          this.careTeams,
        )
      }
      return null
    },
    careTeamIsCompatibleWithHmoClinics(hmoCoreAccreditation, careTeam) {
      if (!isNilOrEmpty(hmoCoreAccreditation.clinics)) {
        const careTeamClinicId = path(['clinic', 'id'], careTeam)
        if (!includes(
          careTeamClinicId,
          hmoCoreAccreditation.clinics,
        )) return false
      }
      return true
    },
    careTeamIsCompatibleWithHmoProfessionals(hmoCoreAccreditation, careTeam) {
      if (!isNilOrEmpty(hmoCoreAccreditation?.professionals)) {
        const filteredProfessionals = filter(
          professional => professional.specialities.some(
            speciality => equals(speciality.id, SPECIALITY.familyCommunityHealth),
          ),
          careTeam.professionals,
        )
        const careTeamProfessionalIds = pluck('id', filteredProfessionals)
        if (!all(
          includes(__, hmoCoreAccreditation.professionals),
          careTeamProfessionalIds,
        )) return false
      }
      return true
    },
    careTeamIsCompatibleWithHmo() {
      const hmoCoreAccreditation = path(
        ['coreAccreditation'],
        this.selectedHmo,
      )

      const careTeamData = this.getPatientCareTeam()

      if (isNil(hmoCoreAccreditation) || isNilOrEmpty(careTeamData)) return true

      if (
        !this.careTeamIsCompatibleWithHmoClinics(hmoCoreAccreditation, careTeamData)
        || !this.careTeamIsCompatibleWithHmoProfessionals(hmoCoreAccreditation, careTeamData)
      ) return false

      return true
    },
    getErrorMessage(fieldPath) {
      const field = path(fieldPath, this.$v)
      if (isNil(field) || !field.$error) return ''

      const fieldLabel = {
        emailSecondary: 'E-mail',
        telphoneSecondary: 'Número',
        healthInsurancePlanNumber: 'Número de carteirinha',
        healthInsurancePlanProductCode: 'Produto',
        cpf: 'CPF',
        zipCode: 'CEP',
      }
      const errorMessages = {
        required: 'Campo obrigatório',
        isValid: `${fieldLabel[last(fieldPath)] || 'Campo'} inválido`,
        minLength: 'Mínimo de caracteres não atingido',
        maxLength: 'Máximo de caracteres atingido',
        invalidDate: 'Data inválida',
        isUnique: `${fieldLabel[last(fieldPath)]} duplicado`,
        isAlreadyRegistered: 'Documento já cadastrado',
        ownerHmoInvalid: 'A operadora do titular e do dependente devem ser iguais',
        sameOwnerAndPatient: 'O paciente não pode ser titular dele mesmo',
        careTeamIsCompatibleWithHmo: 'A equipe de saúde não é compátivel com o plano de saúde do paciente',
      }

      const validatorWithError = find(
        validator => has(validator, field) && !path([validator], field),
        keys(field.$params),
      )

      return errorMessages[validatorWithError]
    },
    async manualEligibilityUpdate() {
      await this.updatePatient()
      this.updateEligibility({
        patientId: this.patientId,
        cardNumber: this.patient.healthInsurancePlanNumber,
        hmoId: this.patient.healthMaintenanceOrganization.id,
        status: this.hmoEligibility.status,
      })
    },
    setEligibility(result, origin) {
      if (origin === 'manual') {
        this.hmoEligibility = {
          status: result,
          manually: true,
          success: result === ELIGIBILITY.status.eligible
            ? 'Validada manualmente'
            : null,
          error: result === ELIGIBILITY.status.notEligible
            ? 'Elegibilidade não validada'
            : null,
        }
        return
      }
      if (!isEmpty(this.hmoEligibility)) this.manualEligibility = result
      this.hmoEligibility = result
    },
    async savePatient() {
      this.$v.$touch()

      if (this.$v.$error) {
        this.setSnackbar({
          status: 'error',
          message: 'Verifique os campos em vermelho',
        })
        return
      }

      if (this.hmoEligibility.manually
        && this.hmoEligibility.status === ELIGIBILITY.status.eligible) {
        this.setShowManualEligibilityDialog(true)
        return
      }

      if (this.patientId) {
        await this.updatePatient()
        return
      }
      this.createPatient()
    },
    createPatient() {
      const patientData = this.getFormattedPatient()
      this.isSaving = true
      this.$http.post('patient', patientData)
        .then(({
          data: {
            id,
            name,
            cpf,
            foreignDocument,
            birthDate,
          },
        }) => {
          this.setSnackbar({
            status: 'success',
            message: 'Paciente cadastrado com sucesso!',
          })

          if (this.virtualReceptionId) {
            this.$emit(
              'setCreatedPatient',
              {
                id,
                name,
                cpf,
                foreignDocument,
                birthDate,
              },
            )
            return
          }

          this.$router.push(`/patient/${id}`)
        })
        .catch(this.showErrorSnackBar)
        .finally(() => { this.isSaving = false })
    },
    async updatePatient(healthInsuranceData = [], alreadySeenChangePlanDialog = false) {
      const changedHMO = path(['oldPatient', 'healthMaintenanceOrganization', 'id'], this)
        !== this.selectedHmo.id
      const hasHmoDependents = !isNilOrEmpty(this.patient.hmoDependents)

      if (this.isOwnerOfHmo
        && changedHMO
        && hasHmoDependents
        && !includes(this.selectedHmo.id, [HMO.sabinSulAmerica, HMO.ritaSaude])
        && !alreadySeenChangePlanDialog
      ) {
        this.setShowChangePlanDialog(true)
        return
      }

      this.isSaving = true
      const patientData = this.getFormattedPatient(healthInsuranceData)

      try {
        const { data } = await this.$http.put(`patient/${this.patientId}`, patientData)
        this.$emit('getPatient')
        this.$emit('patientUpdated', data)
        this.setSnackbar({
          status: 'success',
          message: 'Paciente atualizado com sucesso!',
        })
        this.closeEditDialog()
      } catch (error) {
        this.showErrorSnackBar(error)
      } finally {
        this.isSaving = false
      }
    },
    showErrorSnackBar(errorRes) {
      const errors = path(['response', 'data', 'errors'], errorRes)
      const error = isEmpty(errors)
        ? path(['response', 'data', 'errorCode'], errorRes)
        : errors

      const message = cond([
        [has('responsibleId'), this.getResponsibleError],
        [has('cpf'), this.getDocumentError],
        [has('careTeamId'), always('O Plano de Saúde não é compatível com a equipe de saúde selecionada.')],
        [equals('care_team_reached_score_limit'), always('O limite de pontos da equipe de saúde foi atingido.')],
        [
          has('dependentHealthMaintenanceOrganizationId'),
          always('O(s) agendamento(s) futuro(s) do(s) dependente(s) precisam ser desmarcado(s) antes da operadora do mesmo ser alterado.'),
        ],
        [
          has('healthMaintenanceOrganizationId'),
          always('O(s) agendamento(s) futuro(s) do paciente precisam ser desmarcado(s) antes da operadora do mesmo ser alterado.'),
        ],
        [
          has('healthProductId'),
          always('O(s) agendamento(s) futuro(s) do paciente precisam ser desmarcado(s) antes do plano de saúde do mesmo ser alterado.'),
        ],
        [T, always('Erro ao cadastrar paciente.')],
      ])(error)

      this.setSnackbar({
        status: 'error',
        message,
      })
    },
    getResponsibleError({ responsibleId: responsibleError }) {
      return cond([
        [equals('Instance can not be your responsible'), always('Paciente não pode ser seu próprio responsável.')],
        [equals('Responsible can not have a responsible'), always('Responsável é dependente de outro paciente.')],
        [equals('Instance have a non existent responsible'), always('Responsável não cadastrado.')],
      ])(responsibleError)
    },
    getDocumentError({ cpf: cpfError }) {
      return cond([
        [equals('Instance already exists'), always('Já existe um cadastro com este documento.')],
        [T, always('Documento inválido.')],
      ])(cpfError)
    },
    getMedicalFollowUp() {
      if (this.showMedicalFollowUp) {
        return {
          medicalFollowUpStatus: this.patient.medicalFollowUpStatus,
          medicalFollowUpRefuseReason: this.patient.medicalFollowUpRefuseReason,
        }
      }

      return {
        medicalFollowUpStatus: 'refusedApp',
        medicalFollowUpRefuseReason: 'Paciente sem acesso ao care',
      }
    },
    getFormattedPatient(dependentsData) {
      const formattedFields = {
        birthDate: formatDateToISO8601(this.patient.birthDate),
        cpf: onlyNumber(this.patient.cpf),
        dependentsData,
        ddiTelphone: this.patient.ddiTelphone?.toString(),
        ddiTelphoneSecondary: this.patient.ddiTelphoneSecondary?.toString(),
        foreignDocument: onlyNumber(this.patient.foreignDocument),
        healthInsurancePlanNumber: concat(
          this.patient.healthInsurancePlanProductCode || '',
          this.patient.healthInsurancePlanNumber || '',
        ),
        healthMaintenanceOrganizationId: this.patient.healthMaintenanceOrganization?.id,
        hmoOwnerId: this.selectedOwnerOfHmo?.id,
        responsibleId: this.selectedResponsible?.id,
        needUpdate: false,
        telphone: onlyNumber(this.patient.telphone),
        telphoneSecondary: onlyNumber(this.patient.telphoneSecondary),
        virtualReceptionId: this.virtualReceptionId,
        ...this.getMedicalFollowUp(this.patient),
      }

      let patientData = {
        ...pickAll([
          'address',
          'biologicalSex',
          'bloodType',
          'careTeamId',
          'company',
          'email',
          'emailSecondary',
          'healthInsurancePlanExpiration',
          'healthProductId',
          'isSpecialNeeds',
          'isForeign',
          'name',
          'notes',
          'placeOfBirth',
          'profession',
          'referralId',
          'referralOther',
          'responsibleRelationship',
          'rg',
          'socialName',
          'specialNeedsType',
        ], this.patient),
        ...formattedFields,
      }

      patientData = {
        ...map(
          field => (isNilOrEmpty(field) ? null : field),
          omit(['address'], patientData),
        ),
        address: patientData.address,
      }

      if (isNil(patientData.virtualReceptionId)) {
        patientData = omit(['virtualReceptionId'], patientData)
      }
      if (!this.documentTypeIsCpf) {
        patientData = omit(['cpf'], patientData)
      }
      if (this.documentTypeIsCpf) {
        patientData = omit(['foreignDocument'], patientData)
      }
      if (this.isNonePackageHmo) {
        patientData.careTeamId = null
      }

      return patientData
    },
    resetOwnerOfHmoData() {
      if (this.isOwnerOfHmo) {
        this.selectedOwnerOfHmo = null
        this.$v.selectedOwnerOfHmo.$reset()
      }
    },
    resetCompanyData() {
      this.patient.company = null
      this.$v.hasCompany.$reset()
      this.$v.patient.company.$reset()
    },
    resetPatientHmoData() {
      this.hmoEligibility = {}
      this.patient = {
        ...this.patient,
        healthProductId: null,
        healthInsurancePlanProductCode: null,
      }
      if (this.isAmparoOrAirportHmo) {
        this.patient = {
          ...this.patient,
          healthInsurancePlanNumber: null,
          healthInsurancePlanExpiration: null,
        }
      }
    },
    resetMedicalFollowUpData() {
      if (!this.followUpIsRefused) return

      this.patient = {
        ...this.patient,
        medicalFollowUpRefuseReason: null,
      }
    },
    resetResponsibleData() {
      if (!this.hasResponsible) {
        this.patient = {
          ...this.patient,
          responsibleRelationship: null,
        }
        this.selectedResponsible = null
        this.$v.selectedResponsible.$reset()
        this.$v.patient.responsibleRelationship.$reset()
      }
    },
    resetSpecialNeedsData() {
      if (!this.patient.isSpecialNeeds) {
        this.patient = {
          ...this.patient,
          specialNeedsType: null,
        }
      }
    },
    resetPhoneData({ isSecondaryPhone }) {
      if (isSecondaryPhone) {
        this.patient = {
          ...this.patient,
          telphoneSecondary: null,
        }
      }
      this.patient.telphone = ''
    },
    resetReferralData() {
      if (!this.isReferralOther) this.patient.referralOther = ''
    },
    resetAddress() {
      this.patient = {
        ...this.patient,
        address: {
          street: '',
          number: '',
          complement: '',
          neighborhood: '',
          city: '',
          state: '',
        },
      }
    },
    resetFields() {
      Object.assign(this.$data, this.$options.data.call(this))
      this.$v.$reset()
    },
    setShowChangePlanDialog(value) {
      this.showChangePlanDialog = value
    },
    setShowManualEligibilityDialog(value) {
      this.showManualEligibilityDialog = value
    },
    closeEditDialog() {
      this.$v.$reset()
      this.$emit('close:edit-dialog')
    },
  },
}
</script>

<style lang="stylus" scoped>
.create-patient__section .col
  padding: 4px 8px

</style>
