import clinic from './clinic'

const state = {
  ...clinic.state,
}

const mutations = {
  ...clinic.mutations,
}

const actions = {
  ...clinic.actions,
}

const getters = {
  ...clinic.getters,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
