import Vue from 'vue'
import Vuex from 'vuex'

import agenda from './modules/agenda/index'
import advanced from './modules/advanced'
import announcement from './modules/announcement'
import authentication from './modules/authentication/index'
import billing from './modules/billing/index'
import careCycle from './modules/careCycle'
import careForm from './modules/careForm/index'
import careLine from './modules/careLine/index'
import careTeam from './modules/careTeam'
import clinic from './modules/clinic/index'
import communication from './modules/communication/index'
import disease from './modules/disease'
import eligibility from './modules/eligibility'
import eplPrinter from './modules/eplPrinter'
import emr from './modules/emr'
import exam from './modules/exam'
import examCollection from './modules/examCollection'
import enterprise from './modules/enterprise'
import healthMaintenanceOrganization from './modules/healthMaintenanceOrganization'
import healthProduct from './modules/healthProduct'
import immediateCare from './modules/immediateCare'
import importFileQueue from './modules/importFileQueue'
import patient from './modules/patient'
import patientForm from './modules/patientForm'
import patientImmediateCareVoucher from './modules/patientImmediateCareVoucher'
import patientTermAttachment from './modules/patientTermAttachment'
import preferredNetwork from './modules/preferredNetwork'
import patientRadar from './modules/patientRadar'
import patientRegistration from './modules/patientRegistration'
import profession from './modules/profession'
import professional from './modules/professional'
import referral from './modules/referral'
import schedule from './modules/schedule'
import smartAgenda from './modules/smartAgenda'
import snackbar from './modules/snackbar'
import speciality from './modules/speciality'
import userFeedback from './modules/userFeedback'
import virtualReception from './modules/virtualReception'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    agenda,
    authentication,
    billing,
    careCycle,
    careForm,
    careLine,
    careTeam,
    clinic,
    communication,
    disease,
    eligibility,
    emr,
    exam,
    examCollection,
    enterprise,
    healthMaintenanceOrganization,
    healthProduct,
    immediateCare,
    patient,
    patientForm,
    patientImmediateCareVoucher,
    patientTermAttachment,
    smartAgenda,
    patientRadar,
    patientRegistration,
    preferredNetwork,
    profession,
    professional,
    referral,
    schedule,
    snackbar,
    speciality,
    virtualReception,
    importFileQueue,
    eplPrinter,
    advanced,
    announcement,
    userFeedback,
  },
})
