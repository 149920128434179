import AdvancedToolsPage from '@/pages/AdvancedTools'
import AgendaPage from '@/pages/Agenda'
import AgendaSettingsPage from '@/pages/AgendaSettings'
import AnnouncementPage from '@/pages/Announcement'
import BillListPage from '@/pages/Bill'
import BillingPage from '@/pages/BillingPage'
import CareCycleSettingsPage from '@/pages/CareCycleSettings'
import CareLineAssignsPage from '@/pages/CareLineAssigns'
import CareLineSettingsPage from '@/pages/CareLineSettings'
import CareSettingsPage from '@/pages/CareSettingsPage'
import CareTeamPage from '@/pages/CareTeam'
import ClinicListPage from '@/pages/ClinicList'
import CollectionsPage from '@/pages/CollectionsPage'
import CommunicationSettingsPage from '@/pages/CommunicationSettings'
import ContractItemPage from '@/pages/ContractItem'
import ContractListPage from '@/pages/ContractList'
import CreatePatientPage from '@/pages/CreatePatient'
import Dashboard from '@/components/Dashboard'
import DashboardPage from '@/pages/DashboardPage'
import DiseaseGroupPage from '@/pages/DiseaseGroup'
import EditContractPage from '@/pages/EditContract'
import EditPreferredNetwork from '@/pages/EditPreferredNetwork'
import EmrEncounterPage from '@/pages/EmrEncounter'
import EmrPage from '@/pages/Emr'
import ExamCollectionListPage from '@/pages/ExamCollectionList'
import FinancesPage from '@/pages/Finances'
import FormSettingsPage from '@/pages/FormSettings'
import HmoListPage from '@/pages/HmoList'
import ImmediateCarePage from '@/pages/ImmediateCare'
import IndexPage from '@/pages/Index'
import ItemListPage from '@/pages/ItemList'
import Login from '@/pages/Login'
import ManageUser from '@/pages/AdvancedTools/ManageUser'
import NewAnnouncement from '@/pages/AdvancedTools/NewAnnouncement'
import NewPasswordPage from '@/pages/NewPassword'
import OpenEncountersListPage from '@/pages/OpenEncountersList'
import PatientImportPage from '@/pages/PatientImport'
import PatientProfilePage from '@/pages/PatientProfile'
import PatientRadar from '@/pages/PatientRadar'
import PatientsPage from '@/pages/PatientsPage'
import PermissionListPage from '@/pages/PermissionList'
import PreferredNetworkPage from '@/pages/PreferredNetwork'
import ProfessionalImportPage from '@/pages/ProfessionalImport'
import ReceptionPage from '@/pages/ReceptionPage'
import ReportsAttendancePage from '@/pages/ReportsAttendance'
import Router from 'vue-router'
import ScheduleListPage from '@/pages/ScheduleList'
import SchedulePage from '@/pages/SchedulePage'
import SettingsPage from '@/pages/SettingsPage'
import UserFeedbackListPage from '@/pages/UserFeedbackList'
import UserListPage from '@/pages/UserList'
import store from '@/store/index'
import Vue from 'vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'IndexPage',
      component: IndexPage,
    },
    {
      path: '/patients',
      redirect: '/',
      name: 'patients',
      component: PatientsPage,
      meta: { description: 'Pacientes' },
      children: [
        {
          path: '/create-patient',
          name: 'createPatient',
          component: CreatePatientPage,
          meta: {
            description: 'Cadastrar paciente',
            icon: 'mdi-account-multiple-plus',
          },
        },
        {
          path: '/import-patient',
          name: 'importPatient',
          component: PatientImportPage,
          meta: {
            description: 'Gerenciar Pacientes em lote',
            icon: 'mdi-account-multiple-check',
          },
        },
        {
          path: '/edit-patient/:id',
          name: 'editPatient',
          component: CreatePatientPage,
          meta: {
            description: 'Editar paciente',
            isSiteMapVisible: false,
          },
        },
        {
          path: '/patient/:id',
          name: 'patientProfile',
          component: PatientProfilePage,
          meta: {
            description: 'Perfil do paciente',
            isSiteMapVisible: false,
          },
        },
      ],
    },
    {
      path: '/reception',
      redirect: '/',
      name: 'reception',
      component: ReceptionPage,
      meta: { description: 'Recepção' },
      children: [
        {
          path: '/agenda',
          name: 'agenda',
          component: AgendaPage,
          meta: {
            description: 'Agenda',
            icon: 'mdi-calendar-range',
          },
        },
        {
          path: '/immediate_care/doctor',
          name: 'doctorImmediateCare',
          component: ImmediateCarePage,
          meta: {
            description: 'Amparo Agora - Médico de Família',
            icon: 'mdi-account-multiple',
          },
        },
        {
          path: '/immediate_care/nurse',
          name: 'nurseImmediateCare',
          component: ImmediateCarePage,
          meta: {
            description: 'Amparo Agora - Enfermagem',
            icon: 'mdi-account-multiple',
          },
        },
      ],
    },
    {
      path: '/settings',
      redirect: '/',
      name: 'settings',
      component: SettingsPage,
      meta: { description: 'Configurações' },
      children: [
        {
          path: '/agenda-settings',
          name: 'agendaSettings',
          component: AgendaSettingsPage,
          meta: {
            description: 'Configuração de Agenda',
            icon: 'mdi-calendar-edit',
          },
        },
        {
          path: '/users-list',
          name: 'listUsers',
          component: UserListPage,
          meta: {
            description: 'Configuração de Profissionais',
            icon: 'mdi-account-edit',
          },
        },
        {
          path: '/clinics-list',
          name: 'listClinics',
          component: ClinicListPage,
          meta: {
            description: 'Configuração de Clínica',
            icon: 'mdi-home-edit',
          },
        },
        {
          path: '/hmo-list',
          name: 'editHmo',
          component: HmoListPage,
          meta: {
            description: 'Configuração de Plano de Saúde',
            icon: 'mdi-hospital-box',
          },
        },
        {
          path: '/import-professional',
          name: 'importProfessional',
          component: ProfessionalImportPage,
          meta: {
            description: 'Gerenciar Profissionais em lote',
            icon: 'mdi-account-edit',
          },
        },
        {
          path: '/permission-list',
          name: 'listPermission',
          component: PermissionListPage,
          meta: {
            description: 'Configuração de Permissões',
            icon: 'mdi-clipboard-edit',
          },
        },
        {
          path: '/care-team',
          name: 'careTeamSettings',
          component: CareTeamPage,
          meta: {
            description: 'Configuração de Equipe de Saúde',
            icon: 'mdi-account-group',
          },
        },
        {
          path: '/preferred-network',
          name: 'preferredNetwork',
          component: PreferredNetworkPage,
          meta: {
            description: 'Rede Preferencial',
            icon: 'mdi-plus-thick',
          },
        },
        {
          path: '/preferred-network/edit/:id',
          name: 'editPreferredNetwork',
          component: EditPreferredNetwork,
          meta: {
            description: 'Editar Rede Preferencial',
            isSiteMapVisible: false,
          },
        },
      ],
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: DashboardPage,
      meta: { description: 'Relatórios' },
      children: [
        {
          path: '/report/attendance',
          name: 'attendanceReport',
          component: ReportsAttendancePage,
          meta: {
            description: 'Relatório de Atendimentos',
            icon: 'mdi-file-chart',
          },
        },
        {
          path: '/dashboard/population_panel',
          name: 'populationPanel',
          component: Dashboard,
          meta: {
            description: 'Painel Populacional',
            icon: 'mdi-text-account',
          },
        },
        {
          path: '/dashboard/wallet_panel',
          name: 'walletPanel',
          component: Dashboard,
          meta: {
            description: 'Carteiras',
            icon: 'mdi-folder-account',
          },
        },
        {
          path: '/dashboard/attendance_panel',
          name: 'attendancePanel',
          component: Dashboard,
          meta: {
            description: 'Atendimentos Faturados',
            icon: 'mdi-calendar-check',
          },
        },
        {
          path: '/dashboard/investigation_panel',
          name: 'investigationPanel',
          component: Dashboard,
          meta: {
            description: 'Painel de Investigação',
            icon: 'mdi-view-dashboard',
          },
        },
        {
          path: '/dashboard/clinical_panel',
          name: 'clinicalDashboard',
          component: Dashboard,
          meta: {
            description: 'Relatório Clínico',
            icon: 'mdi-file-chart',
          },
        },
        {
          path: '/dashboard/performance_panel',
          name: 'performanceDashboard',
          component: Dashboard,
          meta: {
            description: 'Relatório de Performance',
            icon: 'mdi-file-chart',
          },
        },

        {
          path: '/dashboard/operational_panel',
          name: 'operationalPanel',
          component: Dashboard,
          meta: {
            description: 'Painel Operacional',
            icon: 'mdi-view-dashboard',
          },
        },
        {
          path: '/dashboard/patient_journey_panel',
          name: 'patientJourneyPanel',
          component: Dashboard,
          meta: {
            description: 'Jornada do Paciente',
            icon: 'mdi-account-heart',
          },
        },
        {
          path: '/dashboard/patient_radar',
          name: 'patientRadar',
          component: PatientRadar,
          meta: {
            description: 'Radar de pacientes',
            icon: 'mdi-card-account-details',
          },
        },
        {
          path: '/dashboard/appointment_panel',
          name: 'appointmentPanel',
          component: Dashboard,
          meta: {
            description: 'Relatório de Agendamentos',
            icon: 'mdi-view-dashboard',
          },
        },
        {
          path: '/dashboard/audit_panel',
          name: 'auditPanel',
          component: Dashboard,
          meta: {
            description: 'Painel de Auditoria',
            icon: 'mdi-file-chart',
          },
        },
        {
          path: '/dashboard/outcome_panel',
          name: 'outcomePanel',
          component: Dashboard,
          meta: {
            description: 'Relatório de Desfecho',
            icon: 'mdi-file-chart',
          },
        },
      ],
    },
    {
      path: '/care-settings',
      redirect: '/',
      name: 'Amparo.care',
      component: CareSettingsPage,
      meta: { description: 'Amparo.Care' },
      children: [
        {
          path: '/form-settings',
          name: 'formSettings',
          component: FormSettingsPage,
          meta: {
            description: 'Configurações de Formulário',
            requiresAdmin: true,
            isSiteMapVisible: true,
            icon: 'mdi-format-list-bulleted',
          },
        },
        {
          path: '/communication-settings',
          name: 'communicationSettings',
          component: CommunicationSettingsPage,
          meta: {
            description: 'Configurações de Comunicado',
            icon: 'mdi-email',
            requiresAdmin: true,
            isSiteMapVisible: true,
          },
        },
        {
          path: '/care-line-settings',
          name: 'careLineSettings',
          component: CareLineSettingsPage,
          meta: {
            description: 'Configurações de Linha de Cuidado',
            icon: 'mdi-ray-start-arrow',
            requiresAdmin: true,
            isSiteMapVisible: true,
          },
        },
        {
          path: '/care-line-assigns/:id',
          name: 'careLineAssigns',
          component: CareLineAssignsPage,
          meta: {
            description: 'Atribuições da Linha de Cuidado',
            requiresAdmin: true,
            isSiteMapVisible: false,
          },
        },
        {
          path: '/care-cycle-settings',
          name: 'careCycleSettings',
          component: CareCycleSettingsPage,
          meta: {
            description: 'Configurações de Ciclos de Cuidado',
            icon: 'mdi-sync',
            requiresAdmin: true,
            isSiteMapVisible: true,
          },
        },
      ],
    },
    {
      path: '/new-password',
      name: 'newPassword',
      component: NewPasswordPage,
      meta: {},
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {},
    },
    {
      path: '/schedule',
      name: 'schedule',
      component: SchedulePage,
      meta: {
        description: 'Tarefas',
        isSiteMapVisible: false,
      },
      children: [
        {
          path: '/schedule/list',
          name: 'scheduleList',
          component: ScheduleListPage,
          meta: {
            description: 'Listagem de Tarefas',
            icon: 'mdi-playlist-play',
            isSiteMapVisible: true,
          },
        },
      ],
    },
    {
      path: '/advanced-tools',
      name: 'advancedTools',
      component: AdvancedToolsPage,
      meta: {
        description: 'Ferramentas Avançadas',
        isSiteMapVisible: true,
      },
      children: [
        {
          path: '/advanced-tools/manage-user',
          name: 'manageUser',
          component: ManageUser,
          meta: {
            description: 'Gerenciar usuários',
            icon: 'mdi-account-cog',
            isSiteMapVisible: true,
          },
        },
        {
          path: '/advanced-tools/new-announcement',
          name: 'newAnnouncement',
          component: NewAnnouncement,
          meta: {
            description: 'Novo Anúncio',
            icon: 'mdi-bullhorn',
          },
        },
        {
          path: '/advanced-tools/list-user-feedback',
          name: 'userFeedbackList',
          component: UserFeedbackListPage,
          meta: {
            description: 'Listagem de Feedbacks',
            icon: 'mdi-comment-quote-outline',
          },
        },
      ],
    },
    {
      path: '/announcement',
      name: 'announcement',
      component: AnnouncementPage,
      meta: {
        description: 'Anúncios',
        isSiteMapVisible: true,
      },
      children: [
        {
          path: '/announcement/new',
          name: 'newAnnouncement',
          component: NewAnnouncement,
          meta: {
            description: 'Novo Anúncio',
            icon: 'mdi-bullhorn',
          },
        },
      ],
    },
    {
      path: '/billing',
      redirect: '/',
      name: 'billing',
      component: BillingPage,
      meta: {
        description: 'Financeiro',
        isSiteMapVisible: true,
      },
      children: [
        {
          path: '/billing/list-bill',
          name: 'billList',
          component: BillListPage,
          meta: {
            description: 'Faturas',
            icon: 'mdi-receipt',
            isSiteMapVisible: true,
          },
        },
        {
          path: '/billing/list-contract',
          name: 'contractList',
          component: ContractListPage,
          meta: {
            description: 'Operadoras e Contratos',
            icon: 'mdi-file-document-edit',
            isSiteMapVisible: true,
          },
        },
        {
          path: '/billing/list-item',
          name: 'itemList',
          component: ItemListPage,
          meta: {
            description: 'Configuração de Itens',
            icon: 'mdi-apps',
            isSiteMapVisible: true,
          },
        },
        {
          path: '/billing/edit-contract/:contractId',
          name: 'editContract',
          component: EditContractPage,
          meta: {
            description: 'Editar Contrato',
            isSiteMapVisible: false,
          },
        },
        {
          path: '/billing/view-contract/:contractId',
          name: 'viewContract',
          component: ContractItemPage,
          meta: {
            description: 'Visualizar Contrato',
            isSiteMapVisible: false,
          },
        },
        {
          path: '/finances-center',
          name: 'financesPage',
          component: FinancesPage,
          meta: {
            description: 'Relatório de Ordem de Serviço',
            icon: 'mdi-file-chart',
            isSiteMapVisible: true,
          },
        },
        {
          path: '/disease_group',
          name: 'diseaseGroupPage',
          component: DiseaseGroupPage,
          meta: {
            description: 'Grupos de Diagnósticos Estendidos',
            icon: 'mdi-file-chart',
            isSiteMapVisible: true,
          },
        },
      ],
    },
    {
      path: '/emr/:id',
      name: 'emr',
      component: EmrPage,
      meta: { description: 'Prontuário', isSiteMapVisible: false },
    },
    {
      path: '/emr/:id/encounter/:encounterId',
      name: 'EmrEncounterPage',
      component: EmrEncounterPage,
      meta: { description: 'Novo atendimento', isSiteMapVisible: false },
    },
    {
      path: '/open-encounters-list',
      name: 'openEncounters',
      component: OpenEncountersListPage,
      meta: {
        description: 'Consultas não finalizadas',
        isSiteMapVisible: false,
      },
    },
    {
      path: '/collection',
      redirect: '/',
      name: 'collection',
      component: CollectionsPage,
      meta: { description: 'Coleta' },
      children: [
        {
          path: '/collection/list',
          name: 'examCollectionList',
          component: ExamCollectionListPage,
          meta: {
            description: 'Agendamentos',
            icon: 'mdi-calendar-edit',
          },
        },
      ],
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  document.title = `${to.meta.description} - Amparo Tech`.replace(
    /undefined -/,
    '',
  )
  const isAnExternalRoute = store.getters['authentication/isAnExternalRoute']

  const loginPath = '/login'
  const isLogged = await JSON.parse(localStorage.getItem('_session'))

  if (from.path === loginPath) {
    return next()
  }

  if (!isLogged && !isAnExternalRoute(to.path)) {
    return next(loginPath)
  }

  if (isLogged && isAnExternalRoute(to.path)) {
    return next('/')
  }

  if (
    !isAnExternalRoute(window.location.pathname)
    && !isAnExternalRoute(to.path)
  ) {
    await store.dispatch('authentication/checkTokenValidation')
  }

  return next()
})

export default router
