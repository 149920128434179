import axios from '@/lib/axios'
import removeNilOrEmpty from '@/utils/removeNilOrEmpty'

export default {
  state: {
    loadingBill: false,
    bills: [],
    billCount: 0,
    billNumberOfPages: 0,
    billPage: 1,
  },
  mutations: {
    SET_LOADING_BILL(state, payload) {
      state.loadingBill = payload
    },
    SET_BILLS(state, payload) {
      state.bills = payload
    },
    SET_BILL_COUNT(state, payload) {
      state.billCount = payload
    },
    SET_BILL_NUMBER_OF_PAGES(state, payload) {
      state.billNumberOfPages = payload
    },
    SET_BILL_PAGE(state, payload) {
      state.billPage = payload
    },
  },
  actions: {
    async listBills({ state, commit }, params = {}) {
      return new Promise((resolve, reject) => {
        const paramsFormatted = {
          ...removeNilOrEmpty(params),
          page: state.billPage,
        }
        axios.get('/billing/bill', { params: paramsFormatted })
          .then(({ data }) => {
            commit('SET_BILLS', data.bills)
            commit('SET_BILL_NUMBER_OF_PAGES', data.numberOfPages)
            commit('SET_BILL_COUNT', data.count)
            resolve(data)
          })
          .catch(reject)
      })
    },
    async createBill(_, attributes) {
      return new Promise((resolve, reject) => {
        axios.post('/billing/bill', attributes)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
    async updateBill(_, { id, attributes }) {
      return new Promise((resolve, reject) => {
        axios.put(`/billing/bill/${id}`, attributes)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
    setBillPage({ commit }, page) {
      commit('SET_BILL_PAGE', page)
    },
  },
}
