<template>
  <v-dialog
    v-model="show"
    max-width="600"
    @click:outside="closeDialog()"
  >
    <v-card>
      <v-card-title
        class="card-dialog__title font-weight-medium pb-0"
      >
        <v-flex>
          {{ title }}
        </v-flex>
        <v-spacer />
        <v-flex shrink>
          <v-btn
            icon
            @click="closeDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-card-title>
      <v-card-text class="pa-4">
        <list-attachments
          :patient="patient"
          :attachments="attachmentsToList"
          :order-id="orderId"
          :limited-list="false"
          :route="route"
          :update-data="updateData"
          empty-list-classes="px-2"
          type="general"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { isNil } from 'ramda'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ListAttachmentDialog',
  components: {
    ListAttachments: () => import('./ListAttachments'),
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Anexos gerais',
    },
    attachments: {
      type: Array,
      default: null,
    },
    patient: {
      type: Object,
      required: true,
    },
    orderId: {
      type: String,
      default: null,
    },
    route: {
      type: String,
      default: '/emr/attachment',
    },
    updateData: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      emrAttachments: 'emr/attachments',
    }),
    show: {
      get() {
        return this.showDialog
      },
      set(value) {
        this.$emit('update:showDialog', value)
      },
    },
    attachmentsToList() {
      return isNil(this.attachments) ? this.emrAttachments : this.attachments
    },
  },
  watch: {
    async show(value) {
      if (value && !this.attachments) await this.listAttachments({ patientId: this.patient.id })
    },
  },
  async mounted() {
    if (!this.attachments) {
      await this.listAttachments({ patientId: this.patient.id })
    }
  },
  methods: {
    ...mapActions({
      listAttachments: 'emr/listAttachments',
    }),
    closeDialog() {
      this.show = false
    },
  },
}
</script>
