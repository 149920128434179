<template>
  <div>
    <v-container
      fluid
      class="px-12 wrapper-component-title"
      :class="{
        'wrapper-component-title--isEmr': emr,
        'wrapper-component-title--isImmediate': immediateCare,
        'wrapper-component-title--isHmo': newHmo,
      }"
    >
      <v-flex>
        <div>
          <h1
            class="title-h2 component-title"
            data-testid="component-title__title--text"
          >
            {{ $route.meta.description }}
          </h1>
          <v-breadcrumbs
            class="breadcrumbs__item not-printable-area"
            :items="breadcrumbs"
          >
            <template slot="divider">
              <v-icon class="breadcrumbs__divider">
                mdi-chevron-right
              </v-icon>
            </template>
          </v-breadcrumbs>
        </div>
        <v-spacer />
        <div class="hidden-sm-and-down not-printable-area btn__align">
          <v-layout
            align-center
            justify-end
            row
            fill-height
          >
            <v-btn
              v-if="printable"
              text
              icon
              color="white"
              @click="printPage"
            >
              <v-icon>mdi-printer</v-icon>
            </v-btn>
            <v-btn
              v-if="settings"
              text
              icon
              color="white"
            >
              <v-icon>mdi-cog</v-icon>
            </v-btn>
            <v-btn
              v-if="userHasAcessToPatientAudit"
              class="btn btn-primary audit_btn"
              @click="showPatientAuditDialog = true"
            >
              Auditoria
              <span class="btn__badge">
                <badge :number="patientRegistrationLength" />
              </span>
            </v-btn>
            <v-btn
              v-if="userHasAccessToVirtualReception"
              class="btn btn-primary virtual-reception_btn ml-3"
              :class="{
                'virtual-reception_btn--isActive': isUserInAttendance,
              }"
              @click="toggleVirtualReceptionDialog"
            >
              {{ virtualReceptionButtonTitle }}
              <span class="btn__badge">
                <badge :number="openAttendances" />
              </span>
            </v-btn>
            <v-btn
              v-if="newPatient"
              color="primary"
              class="btn new-patient_btn ml-3"
              @click="showCreatePatientDialog = true"
            >
              Novo Paciente
            </v-btn>
            <v-btn
              v-if="newProfessional"
              color="primary"
              class="btn"
              @click="showCreateProfessionalDialog = true"
            >
              Novo Profissional
            </v-btn>
            <v-btn
              v-if="newClinic"
              color="primary"
              class="btn"
              data-testid="clinic__new-clinic--button"
              @click="showCreateClinicDialog = true"
            >
              Nova Clínica
            </v-btn>
            <v-btn
              v-if="newHmo && hasAccessToCreateHmo"
              color="primary"
              class="btn"
              data-testid="new-hmo--button"
              @click="showCreateHmoDialog = true"
            >
              Nova Operadora
            </v-btn>
            <v-btn
              v-if="newPermission"
              color="primary"
              class="btn"
              @click="showCreatePermissionDialog = true"
            >
              Nova Permissão
            </v-btn>
            <v-btn
              v-if="newUser"
              color="primary"
              class="btn"
              @click="showCreateUserDialog = true"
            >
              Novo Usuário
            </v-btn>
            <v-btn
              v-if="newCareTeam"
              color="primary"
              class="btn"
              @click="showCreateCareTeamDialog = true"
            >
              Nova Equipe de Saúde
            </v-btn>
            <v-btn
              v-if="newItem"
              color="primary"
              class="btn"
              @click="showCreateItemDialog = true"
            >
              Novo Item
            </v-btn>
            <slot
              v-if="isReport"
              name="report"
            />
            <v-flex v-if="isNewEncounter">
              <emr-print-button
                v-if="emrData"
                :emr-data="emrData"
                :button-color="'white'"
              />
              <emr-cancel-encounter class="mr-2" />
              <emr-end-encounter />
            </v-flex>
            <slot />
          </v-layout>
        </div>
      </v-flex>
    </v-container>
    <v-dialog
      v-if="newPatient"
      v-model="showCreatePatientDialog"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>Cadastrar Paciente</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            dark
            @click="showCreatePatientDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <create-patient />
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="newClinic"
      v-model="showCreateClinicDialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1400"
    >
      <v-card>
        <v-card-title
          class="card-dialog__title"
        >
          Cadastrar Clínica
          <v-spacer />
          <v-btn
            icon
            @click="showCreateClinicDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <create-clinic
          @closeCreateClinicDialog="closeCreateClinicDialog"
        />
      </v-card>
    </v-dialog>

    <create-hmo
      v-if="showCreateHmoDialog"
      @closeCreateHmoDialog="closeCreateHmoDialog"
    />

    <v-dialog
      v-if="newPermission"
      v-model="showCreatePermissionDialog"
      transition="dialog-bottom-transition"
      persistent
      fullscreen
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>Cadastrar Permissão</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            dark
            @click="showCreatePermissionDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <CreatePermission
          @closeCreatePermissionDialog="closeCreatePermissionDialog"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="newUser"
      v-model="showCreateUserDialog"
      transition="dialog-bottom-transition"
      fullscreen
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>Cadastrar Usuário</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            dark
            @click="showCreateUserDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <create-user
          v-if="showCreateUserDialog"
          @closeCreateUserDialog="showCreateUserDialog = false"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="isAgenda"
      v-model="showPatientAuditDialog"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>
            Auditoria
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            dark
            @click="closePatientAuditDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <audit-container
          @audited="getPatientRegistrationLength()"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="isAgenda"
      v-model="isOpenVirtualReceptionDialog"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>
            Recepção virtual
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            dark
            @click="toggleVirtualReceptionDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <virtual-reception-container />
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="newCareTeam && showCreateCareTeamDialog"
      :value="showCreateCareTeamDialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1400"
    >
      <v-card>
        <v-card-title class="mb-4">
          Equipe de Saúde
          <v-spacer />
          <v-btn
            icon
            dark
            @click="showCreateCareTeamDialog = false"
          >
            <v-icon color="black">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <create-care-team
            @update="$emit('update:care-team')"
            @close="showCreateCareTeamDialog = false"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <create-item
      v-if="showCreateItemDialog"
      @closeDialog="showCreateItemDialog = false"
    />
  </div>
</template>

<script>
import EmrCancelEncounter from '@/pages/Emr/EmrCancelEncounter'
import EmrEndEncounter from '@/pages/Emr/EmrEndEncounter'
import EmrPrintButton from '@/pages/Emr/EmrPrintButton'
import Badge from '@/components/UI/Badge'
import {
  and,
  isEmpty,
  path,
} from 'ramda'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ComponentTitle',
  components: {
    CreatePatient: () => import('@/components/CreatePatient'),
    EmrCancelEncounter,
    EmrEndEncounter,
    EmrPrintButton,
    Badge,
    CreateClinic: () => import('@/pages/ClinicList/CreateClinic'),
    CreateUser: () => import('@/pages/UserList/CreateUser'),
    CreateHmo: () => import('@/pages/HmoList/CreateHmo'),
    CreatePermission: () => import('@/pages/PermissionList/CreatePermission'),
    VirtualReceptionContainer: () => import('@/components/VirtualReception'),
    AuditContainer: () => import('@/components/Audit/AuditContainer'),
    CreateCareTeam: () => import('@/pages/CareTeam/CreateCareTeam'),
    CreateItem: () => import('@/pages/ItemList/CreateItem'),
  },
  props: {
    isAgenda: Boolean,
    printable: Boolean,
    newPatient: Boolean,
    newProfessional: Boolean,
    newClinic: Boolean,
    newHmo: Boolean,
    newPermission: Boolean,
    newUser: Boolean,
    newCareTeam: Boolean,
    newQuestion: Boolean,
    newItem: Boolean,
    emr: Boolean,
    immediateCare: Boolean,
    isNewEncounter: Boolean,
    isReport: Boolean,
    settings: {
      default: '',
      type: String,
    },
    lastBreadcrumb: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      showCreatePatientDialog: false,
      showCreateClinicDialog: false,
      showCreateHmoDialog: false,
      showCreatePermissionDialog: false,
      showCreateUserDialog: false,
      showPatientAuditDialog: false,
      showCreateCareTeamDialog: false,
      showCreateItemDialog: false,
    }
  },
  computed: {
    ...mapGetters({
      encounter: 'emr/encounter',
      isUserInAttendance: 'virtualReception/isUserInAttendance',
      openAttendances: 'virtualReception/openAttendances',
      patientRegistrationLength: 'patientRegistration/patientRegistrationLength',
      showVirtualReceptionDialog: 'virtualReception/showVirtualReceptionDialog',
      user: 'authentication/user',
      userHasAccessToFunctionality: 'authentication/userHasAccessToFunctionality',
    }),
    doctorCpf() {
      return path(
        [
          'professional',
          'cpf',
        ],
        this.user,
      )
    },
    userHasAccessToVirtualReception() {
      return and(
        this.isAgenda,
        this.userHasAccessToFunctionality.virtualReception,
      )
    },
    userHasAcessToPatientAudit() {
      return and(
        this.isAgenda,
        this.userHasAccessToFunctionality.patientAudit,
      )
    },
    virtualReceptionButtonTitle() {
      return this.isUserInAttendance
        ? 'Voltar ao atendimento'
        : 'Recepção virtual'
    },
    isOpenVirtualReceptionDialog: {
      get() { return this.showVirtualReceptionDialog },
      set() { this.toggleVirtualReceptionDialog() },
    },
    breadcrumbs() {
      const breadcrumb = [{
        text: 'Início',
        to: '/',
      }]
      for (let index = 0; index < this.$route.matched.length; index += 1) {
        breadcrumb.push(
          {
            text: this.$route.matched[index].meta.description,
            to: this.$route.matched[index].path,
          },
        )
      }
      const lastItem = breadcrumb.pop()
      lastItem.disabled = true
      if (this.$route.params.id && !isEmpty(this.lastBreadcrumb)) {
        lastItem.text = this.lastBreadcrumb
      }
      breadcrumb.push(lastItem)
      return breadcrumb
    },
    emrData() {
      return isEmpty(this.encounter) ? null : this.encounter
    },
    hasAccessToCreateHmo() {
      return this.userHasAccessToFunctionality.listHmo
    },
  },
  created() {
    this.getPatientRegistrationLength()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/setSnackbar',
      toggleVirtualReceptionDialog: 'virtualReception/toggleVirtualReceptionDialog',
      listPatientRegistrationLength: 'patientRegistration/listPatientRegistrationLength',
    }),
    buildSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    printPage() {
      window.print()
    },
    closeCreateClinicDialog() {
      this.showCreateClinicDialog = false
    },
    closeCreateHmoDialog() {
      this.showCreateHmoDialog = false
    },
    closeCreatePermissionDialog() {
      this.showCreatePermissionDialog = false
    },
    closePatientAuditDialog() {
      this.showPatientAuditDialog = false
      this.getPatientRegistrationLength()
    },
    async getPatientRegistrationLength() {
      this.listPatientRegistrationLength({
        status: 'todo',
      })
    },
  },
}
</script>

<style lang='stylus' scoped>
@import '../../style/_core/colors.styl'

.wrapper-component-title
  width 100%
  height 125px
  background-color secondary-color

.wrapper-component-title--isEmr
  background-color $evening-sea

.wrapper-component-title--isImmediate
  background-color $evening-sea

.wrapper-component-title--isHmo
  background-color $evening-sea

.title-h2.component-title
  color #fff

.btn.v-btn
  height 56px !important
  border-radius 8px

.virtual-reception_btn
  width 269px

.virtual-reception_btn--isActive
  width: 326px

.audit_btn
  width 196px

.new-patient_btn
  width: 238px

.v-breadcrumbs.breadcrumbs__item
  padding 6px 0px

.btn__badge
  position absolute
  right 30px

.btn__align
  align-self center !important
</style>
