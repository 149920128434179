<template>
  <div>
    <v-container
      fluid
      class="px-12 filters-container"
    >
      <v-row
        class="mt-3"
      >
        <v-col>
          <v-autocomplete
            v-model="filters.selectedClinic"
            :items="clinics"
            item-text="name"
            item-value="id"
            label="Unidade"
            no-data-text="Digite para buscar"
            append-icon=""
            clearable
            filled
            return-object
            data-testid="agenda-settings__clinics--select"
            @change="setFilters(filters)"
          />
        </v-col>

        <v-col>
          <v-autocomplete
            v-model="filters.selectedSpeciality"
            :items="specialities"
            item-text="name"
            item-value="id"
            filled
            clearable
            label="Especialidade"
            no-data-text="Digite para buscar"
            append-icon=""
            return-object
            @change="setFilters(filters)"
          />
        </v-col>

        <v-col>
          <search-professional
            :key="`${professionalId}`"
            :professional-id="professionalId"
            @input="updateProfessional($event)"
          />
        </v-col>

        <v-col
          v-if="hasAllFiltersFilled"
        >
          <v-select
            v-model="filters.selectedStatus"
            :items="status"
            item-text="text"
            item-value="value"
            filled
            clearable
            label="Status"
            append-icon=""
            @change="setFilters(filters)"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-container
      v-if="!hasFiltersFilled"
      fluid
    >
      <h2 class="info-text font-weight-lighter">
        Preencha os campos acima para buscar.
      </h2>
    </v-container>

    <v-container
      v-else-if="!areFiltersFulfilled"
      fluid
    >
      <agenda-settings-full-list
        v-if="!loadingAgenda"
        instance-type="agendas"
        @setFilters="setFilters"
      />
    </v-container>
    <v-container
      v-else
      fluid
    >
      <v-row
        class="justify-center"
      >
        <v-col
          cols="4"
        >
          <v-btn
            class="font-weight-bold my-6"
            color="primary"
            block
            large
            data-testid="agenda-settings__new-agenda--button"
            @click="openItemDialog()"
          >
            Novo Item
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            v-if="!loadingAgenda"
            hide-default-footer
            disable-pagination
            class="data-table__header"
            no-data-text="Sem itens para exibir, clique no botão acima para começar a criar"
            :headers="headers"
            :items="availableAgendas"
          >
            <template v-slot:item="agenda">
              <tr>
                <td>{{ agenda.item.dayOfTheWeek | dayOfTheWeek }}</td>
                <td>{{ agenda.item.startDate | formatDate }}</td>
                <td v-if="agenda.item.endDate">
                  {{ agenda.item.endDate | formatDate }}
                </td>
                <td v-else>
                  -
                </td>
                <td>{{ agenda.item.startTime | removeSecondsFromTime }}</td>
                <td>{{ agenda.item.endTime | removeSecondsFromTime }}</td>
                <td>{{ agenda.item.interval }} minutos</td>
                <td>{{ agenda.item.reserves.length ? agenda.item.reserves[0].name : '' }}</td>
                <td>{{ agenda.item.maxSlots }}</td>
                <td>{{ agenda.item.maxAppointmentsBySlot }}</td>
                <td>
                  <div
                    v-if="!isBlockAgenda(agenda.item)"
                  >
                    <v-btn
                      icon
                      fab
                      @click="openItemDialog(agenda.item)"
                    >
                      <v-icon>
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </div>
                  <div>
                    <v-btn
                      icon
                      fab
                      @click="toggleArchiveAgendaDialog(agenda.item)"
                    >
                      <v-icon>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <agenda-settings-items-pagination
        class="mb-6"
        @changePage="setPage"
      />
    </v-container>

    <archive-agenda-dialog
      v-if="isArchiveAgendaDialogOpen"
      @archiveAgenda="archiveAgenda"
      @closeArchiveAgendaDialog="toggleArchiveAgendaDialog"
    />

    <v-dialog
      v-model="showDialogRegisterAgendaPattern"
      fullscreen
      transition="dialog-bottom-transition"
      persistent
      @keydown.esc="showDialogRegisterAgendaPattern = false"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title
            v-if="agendaToEdit"
            class="ml-6"
          >
            Editar configurações de agenda
          </v-toolbar-title>
          <v-toolbar-title v-if="!agendaToEdit">
            Nova configuração de agenda
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            dark
            @click="showDialogRegisterAgendaPattern = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <agenda-register-item
          v-if="showDialogRegisterAgendaPattern"
          :clinic="filters.selectedClinic"
          :speciality="filters.selectedSpeciality"
          :professional="filters.selectedProfessional"
          :agenda-to-edit="agendaToEdit"
          @getAgenda="listAgenda"
          @closeAgendaDialog="closeAgendaDialog"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  all,
  any,
  clone,
  equals,
  isNil,
  lt,
  pick,
  values,
} from 'ramda'

import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

import SearchProfessional from '@/components/Common/SearchProfessional'
import AgendaSettingsItemsPagination from './AgendaSettingsItemsPagination'

export default {
  name: 'AgendaSettingsItems',
  components: {
    AgendaRegisterItem: () => import('./AgendaRegisterItem'),
    SearchProfessional,
    AgendaSettingsItemsPagination,
    ArchiveAgendaDialog: () => import('./ArchiveAgendaDialog'),
    AgendaSettingsFullList: () => import('@/pages/AgendaSettings/AgendaSettingsFullList'),
  },
  data: () => ({
    isArchiveAgendaDialogOpen: false,
    agendaToEdit: null,
    itemToArchive: '',
    showDialogRegisterAgendaPattern: false,
    status: [
      {
        value: 'open',
        text: 'Aberto',
      },
      {
        value: 'finished',
        text: 'Finalizado',
      },
    ],
    headers: [
      {
        text: 'Dia da semana',
        value: 'dayOfTheWeek',
      },
      {
        text: 'Início',
        value: 'startDate',
      },
      {
        text: 'Fim',
        value: 'endDate',
      },
      {
        text: 'Hora início',
        value: 'startTime',
      },
      {
        text: 'Hora fim',
        value: 'endTime',
      },
      {
        text: 'Intervalo',
        value: 'interval',
      },
      {
        text: 'Reserva',
        value: 'reserves.name',
      },
      {
        text: 'Estendido',
        value: 'maxSlots',
      },
      {
        text: 'Máx. de agendamentos por horário',
        value: 'maxAppointmentsBySlot',
      },
      {
        text: 'Ação',
        value: 'action',
        sortable: false,
      },
    ],
    filters: {
      selectedClinic: null,
      selectedSpeciality: null,
      selectedProfessional: null,
      selectedStatus: null,
    },
  }),
  computed: {
    ...mapGetters({
      availableAgendas: 'agenda/availableAgendas',
      clinics: 'clinic/clinics',
      loadingAgenda: 'agenda/loadingAgenda',
      page: 'agenda/agendaPage',
      specialities: 'speciality/specialities',
    }),
    areFiltersFulfilled() {
      return !(any(isNil, values(this.filters)))
    },
    hasFiltersFilled() {
      return !(all(isNil, values(this.filters)))
    },
    hasAllFiltersFilled() {
      return !any(
        equals(null),
        values(pick(['selectedClinic', 'selectedSpeciality', 'selectedProfessional'], this.filters)),
      )
    },
    professionalId() {
      return this.filters?.selectedProfessional?.id
    },
  },
  watch: {
    hasAllFiltersFilled(newValue) {
      if (newValue) {
        this.filters.selectedStatus = 'open'
      }
    },
  },
  methods: {
    ...mapActions({
      getProfessional: 'professional/getProfessional',
      listAgenda: 'agenda/listAgenda',
      setAgendaFiltersData: 'agenda/setAgendaFiltersData',
      setAgendaPage: 'agenda/setAgendaPage',
      setSnackbar: 'snackbar/setSnackbar',
      storeAgenda: 'agenda/storeAgenda',
    }),
    async setPage(page) {
      this.setAgendaPage(page)
      await this.listAgenda()
    },
    isBlockAgenda(agenda) {
      return lt(agenda.endDate, moment().format('YYYY-MM-DD'))
    },
    openItemDialog(agenda) {
      this.showDialogRegisterAgendaPattern = true

      if (isNil(agenda)) {
        this.agendaToEdit = {}
        return
      }

      this.agendaToEdit = agenda
    },
    closeAgendaDialog() {
      this.showDialogRegisterAgendaPattern = false
      this.agendaToEdit = null
    },
    toggleArchiveAgendaDialog(item) {
      this.itemToArchive = item
      this.isArchiveAgendaDialogOpen = !this.isArchiveAgendaDialogOpen
    },
    async setFilters(filters) {
      this.filters = filters
      this.setAgendaFiltersData(clone(this.filters))
      await this.listAgenda()
    },
    async archiveAgenda() {
      try {
        await this.storeAgenda(
          {
            id: this.itemToArchive.id,
            attributes: {
              storedAt: moment().format('YYYY-MM-DD HH:mm'),
            },
          },
        )
        await this.listAgenda()
      } catch (error) {
        let message = 'Falha ao arquivar, tente novamente'
        if (equals(error?.response?.data?.errorCode, 'agenda_delete_invalid')) {
          message = 'Existem consultas registradas em períodos futuros'
        }
        this.setSnackbar({ status: 'error', message })
      }
      this.isArchiveAgendaDialogOpen = false
    },
    updateProfessional(event) {
      this.filters.selectedProfessional = event
      this.setFilters(this.filters)
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../../style/_core/colors.styl'

.info-text
  color rgba(0, 0, 0, 0.38)
  @media only screen and (min-width: 601px)
    font-size 32px
  @media only screen and (min-width: 1201px)
    font-size 48px
  @media only screen and (min-width: 1601px)
    font-size 64px

.data-table__header >>> .v-data-table-header
  background-color #f6f6f6 !important
  box-shadow 0px 3px 1px rgba(224, 224, 224, 0.9)
  padding 1rem 0 !important
</style>
