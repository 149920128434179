<template>
  <div class="filters-container py-4">
    <v-container
      fluid
      class="px-11"
    >
      <v-row>
        <v-col
          class="pb-0"
          cols="3"
        >
          <v-autocomplete
            v-model="filters.clinicIds"
            :items="clinics"
            item-text="name"
            item-value="id"
            label="Unidade"
            no-data-text="Digite para buscar"
            hint="Obrigatorio"
            persistent-hint
            multiple
            clearable
            filled
            dense
            @change="setFilters(filters)"
          />
        </v-col>
        <v-col
          class="pb-0"
          cols="3"
        >
          <vc-date-picker
            v-model="filters.date"
            locale="pt-br"
            color="green"
          >
            <template v-slot="{ inputEvents }">
              <v-text-field
                :value="formattedDate(filters.date)"
                label="Data"
                append-icon="mdi-calendar"
                hint="Obrigatorio"
                persistent-hint
                readonly
                filled
                clearable
                dense
                v-on="inputEvents"
                @click:clear="clearDate()"
              />
            </template>
          </vc-date-picker>
        </v-col>
        <v-col
          class="pb-0"
          cols="3"
        >
          <v-autocomplete
            v-model="filters.startTime"
            :items="startTimeHoursList"
            label="Horário"
            filled
            dense
            hide-details
            clearable
            @change="setFilters(filters)"
          />
        </v-col>
        <v-col
          class="pb-0"
          cols="3"
        >
          <v-autocomplete
            v-model="filters.professionalIds"
            :items="professionalsItems"
            :search-input.sync="searchProfessional"
            label="Executante"
            item-text="name"
            item-value="id"
            hide-details
            dense
            clearable
            filled
            multiple
            hide-select
            no-data-text="Digite para buscar"
            @change="setFilters(filters)"
          />
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="filters.statuses"
            :items="appointmentStatuses"
            label="Status"
            item-text="label"
            item-value="value"
            hide-details
            filled
            dense
            multiple
            clearable
            @change="setFilters(filters)"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import isNilOrEmpty from '@/utils/dataValidators'
import moment from 'moment'
import { debounce } from 'lodash'
import {
  omit,
  reject,
  values,
  isEmpty,
} from 'ramda'
import { APPOINTMENT } from 'amparo-enums'
import { required } from 'vuelidate/lib/validators'

const { mapActions, mapGetters } = require('vuex')

export default {
  emits: ['update:filters'],
  name: 'ExamCollectionListFilters',
  data() {
    return {
      filters: {
        date: null,
        startTime: null,
        clinicIds: null,
        professionalIds: null,
        statuses: null,
      },
      searchProfessional: null,
      professionalsItems: [],
    }
  },
  validations() {
    return {
      filters: {
        clinicIds: { required },
        date: { required },
      },
    }
  },
  computed: {
    ...mapGetters({
      professionals: 'professional/professionals',
      clinics: 'clinic/clinics',
    }),
    startTimeHoursList() {
      const startTimeHoursList = []
      const startTime = '00:00'
      const endTime = '23:55'
      const time = moment(startTime, 'HH:mm')

      startTimeHoursList.push(time.format('HH:mm'))

      while (moment(time, 'HH:mm') < moment(endTime, 'HH:mm')) {
        time.add(5, 'minutes')
        startTimeHoursList.push(time.format('HH:mm'))
      }

      return startTimeHoursList
    },
    appointmentStatuses() {
      return values(
        omit([
          APPOINTMENT.status.rescheduled.value,
          APPOINTMENT.status.unsuccessfulContact.value,
          APPOINTMENT.status.received.value,
        ],
        APPOINTMENT.status),
      )
    },
    defaultStatusValues() {
      return this.appointmentStatuses
        .map(status => status.value)
        .filter(value => value !== APPOINTMENT.status.unchecked.value)
    },
  },
  watch: {
    searchProfessional: debounce(function searchProfessional(name) {
      if (!name) return
      this.listProfessional({ name })
    }, 600),
    professionals(professionals) {
      this.professionalsItems = [...professionals, ...this.filters.professionalId || []]
    },
    'filters.date': function dateFilter() {
      this.setFilters()
    },
  },
  async mounted() {
    this.listProfessional()
    this.setDefaultValues()
    await this.listClinic()
    if (!isEmpty(this.clinics)) {
      this.filters.clinicIds = [this.clinics[0].id]
      this.setFilters()
    }
  },
  async activated() {
    this.setFilters()
  },
  methods: {
    ...mapActions({
      listClinic: 'clinic/listClinic',
      listProfessional: 'professional/listProfessional',
    }),
    clearDate() {
      this.filters.date = null
    },
    formattedDate(date) {
      return isNilOrEmpty(date) ? null : moment(date).format('DD/MM/YYYY')
    },
    setDefaultValues() {
      this.filters.statuses = this.defaultStatusValues
      this.filters.date = moment().format('YYYY-MM-DD')
      this.setFilters()
    },
    setFilters() {
      const formattedFilters = reject(isNilOrEmpty, {
        ...this.filters,
        date: this.filters.date
          ? moment(this.filters.date).format('YYYY-MM-DD')
          : null,
        startTime: this.filters.startTime
          ? moment(this.filters.startTime, 'HH:mm').format('HH:mm:ss')
          : null,
      })

      this.$emit(
        'update:filters',
        {
          filters: formattedFilters,
          isInvalid: this.$v.filters.$invalid,
        },
      )
    },
  },
}
</script>
