const {
  isNil,
  isEmpty,
} = require('ramda')
const filterDeep = require('deepdash/filterDeep')

const removeNilOrEmptyFromObject = object => filterDeep(
  object,
  value => !isNil(value) && !isEmpty(value),
)

module.exports = removeNilOrEmptyFromObject
