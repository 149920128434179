<template>
  <div>
    <div class="filters-container">
      <v-container
        :class="{
          'container--fluid': true,
          'px-12': $vuetify.breakpoint.smAndUp,
          'pt-6': true,
          'grid-list-lg': true
        }"
      >
        <v-row>
          <v-col
            cols="3"
          >
            <v-text-field
              v-model="filters.name"
              filled
              label="Buscar por nome"
              clearable
              no-data-text="Digite para buscar"
            />
          </v-col>
          <v-col
            cols="3"
          >
            <v-text-field
              v-model="filters.tussCode"
              label="Buscar por TUSS"
              clearable
              append-icon
              filled
              hide-no-data
            />
          </v-col>

          <v-col
            cols="3"
          >
            <v-select
              v-model="filters.type"
              label="Tipo do Item"
              :items="itemTypeValues"
              item-text="label"
              item-value="value"
              clearable
              append-icon
              filled
              hide-no-data
            />
          </v-col>

          <v-col
            cols="3"
          >
            <v-btn
              class="btn-filter--height font-weight-lightbold"
              color="dark-green"
              dark
              block
              large
              @click="getItems"
            >
              <v-icon left>
                mdi-magnify
              </v-icon>
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-container
      :class="{
        'container--fluid': true,
        'px-12': $vuetify.breakpoint.smAndUp,
      }"
    >
      <v-row
        v-if="isItemsEmpty"
        class="mt-2 mb-2"
      >
        <h2 class="info-text font-weight-lighter">
          Nenhum resultado encontrado.
        </h2>
      </v-row>

      <v-row
        v-else-if="isLoading"
      >
        <v-col
          class="d-flex justify-center"
        >
          <circular-loader />
        </v-col>
      </v-row>

      <template v-else>
        <v-row
          v-for="(item, index) in items"
          :key="index"
          :class="{
            'list-item': true,
            'mt-2': true,
            'list-item--disabled': Boolean(item.storedAt),
          }"
        >
          <v-col
            cols="11"
            md11
            sm11
            xs12
          >
            <div>
              <span class="list-item__title">
                {{ item.name }}
              </span>

              <div
                class="list-item__detail"
              >
                <span>
                  Tipo: {{ item.type | formatItemType }}
                </span>
              </div>

              <div
                class="list-item__detail"
              >
                <span>
                  TUSS: {{ item.tussCode }}
                </span>
              </div>
            </div>
          </v-col>
          <v-col
            v-if="!Boolean(item.storedAt)"
            cols="1"
            class="list-item__icon d-flex justify-end"
          >
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  class="mr-2 font-weight-lightbold"
                  text
                  icon
                  v-on="on"
                  @click="openListMnemonicsDialog(item)"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Visualizar</span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  class="mr-2 font-weight-lightbold"
                  text
                  icon
                  v-on="on"
                  @click="openEditDialog(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  class="mr-2 font-weight-lightbold"
                  text
                  icon
                  v-on="on"
                  @click="openArchiveDialog(item)"
                >
                  <v-icon>mdi-archive-arrow-down</v-icon>
                </v-btn>
              </template>
              <span>Arquivar</span>
            </v-tooltip>
          </v-col>
          <v-col
            v-else
            cols="1"
            class="list-item__icon d-flex justify-end"
          >
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  class="mr-2 font-weight-lightbold"
                  text
                  icon
                  v-on="on"
                  @click="handleRestoreItem(item.id)"
                >
                  <v-icon>mdi-archive-arrow-up</v-icon>
                </v-btn>
              </template>
              <span>Restaurar</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>

      <item-list-container-pagination
        @changePage="handleChangePage"
      />

      <create-item
        v-if="showEditItemDialog"
        :item-to-edit="editedItem"
        @closeDialog="closeEditDialog"
      />

      <item-mnemonic-list
        v-if="showItemMnemonicListDialog"
        :item="editedItem"
        @closeDialog="closeListMnemonicsDialog"
      />

      <archive-item-dialog
        v-if="showArchiveItemDialog"
        :item-id="editedItem.id"
        @closeDialog="closeArchiveDialog($event)"
      />
    </v-container>
  </div>
</template>

<script>
import {
  isEmpty,
  values,
} from 'ramda'
import { mapGetters, mapActions } from 'vuex'
import { debounce } from 'lodash'
import { ITEM } from 'amparo-enums'
import ItemListContainerPagination from './ItemListContainerPagination'

export default {
  name: 'ItemListContainer',
  components: {
    ItemListContainerPagination,
    CreateItem: () => import('./CreateItem'),
    ItemMnemonicList: () => import('./ItemMnemonicList'),
    ArchiveItemDialog: () => import('./ArchiveItemDialog'),
    CircularLoader: () => import('@/components/UI/CircularLoader'),
  },
  data() {
    return {
      editedItem: {},
      showEditItemDialog: false,
      showItemMnemonicListDialog: false,
      showArchiveItemDialog: false,
      filters: {},
      itemTypeValues: values(ITEM.types),
    }
  },
  computed: {
    ...mapGetters({
      items: 'billing/items',
      itemPage: 'billing/itemPage',
      isLoading: 'billing/isLoadingItem',
    }),
    isItemsEmpty() {
      return isEmpty(this.items)
    },
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler: debounce(function searchItems() {
        this.getItems()
      }, 500),
    },
  },
  mounted() {
    this.getItems()
  },
  methods: {
    ...mapActions({
      listClinic: 'clinic/listClinic',
      listItem: 'billing/listItem',
      restoreItem: 'billing/restoreItem',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    async getItems() {
      await this.listItem({
        ...this.filters,
        page: this.itemPage,
      })
    },
    openEditDialog(itemData) {
      this.editedItem = itemData
      this.showEditItemDialog = true
    },
    closeEditDialog() {
      this.editedItem = {}
      this.showEditItemDialog = false
      this.getItems()
    },
    openListMnemonicsDialog(itemData) {
      this.editedItem = itemData
      this.showItemMnemonicListDialog = true
    },
    closeListMnemonicsDialog() {
      this.editedItem = {}
      this.showItemMnemonicListDialog = false
      this.getItems()
    },
    openArchiveDialog(itemData) {
      this.editedItem = itemData
      this.showArchiveItemDialog = true
    },
    closeArchiveDialog(hasDeleteItem) {
      this.editedItem = {}
      this.showArchiveItemDialog = false
      if (hasDeleteItem) this.getItems()
    },
    handleChangePage() {
      this.getItems()
    },
    async handleRestoreItem(itemId) {
      try {
        await this.restoreItem(itemId)
        this.setSnackbar({
          status: 'success',
          message: 'Item restaurado com sucesso',
        })
        this.getItems()
      } catch (err) {
        this.setSnackbar({
          status: 'error',
          message: 'Erro ao restaurar item',
        })
      }
    },
  },
}
</script>

<style lang='stylus' scoped>
@import '../../style/_core/colors.styl'
.list-item
  width 100%
  padding 20px
  border-radius 8px
  border solid 1px #e0e0e0
  margin-bottom 10px

.list-item__title
  font-size 24px
  color rgba(0, 0, 0, 0.87)

.list-item__detail
  font-size 16px
  line-height 2
  letter-spacing 0.5px
  color rgba(0, 0, 0, 0.6)

.list-item--disabled
  > div, span
    color #32333826

.list-item__icon
  margin-top -8px

.info-text
  letter-spacing -0.5px
  color rgba(0, 0, 0, 0.38)
  @media only screen and (min-width 601px)
    font-size 32px
  @media only screen and (min-width 1201px)
    font-size 48px
  @media only screen and (min-width 1601px)
    font-size 64px

.filters-container
  width 100%
  height auto
  background-color #e8f8e8
  @media only screen and (min-width 601px)
    height 108px

.v-card
  padding 10px
  border-radius 6px

.v-btn
  letter-spacing 1px

.v-btn.v-btn--large
  margin-top 0px
  height 56px
  border-radius 8px

.v-btn.v-btn--text
  border solid 1px thin-gray

.v-btn.btn-dialog
  padding 15px
  @media all and (min-width 601px)
    width 190px
</style>
