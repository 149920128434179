<template>
  <v-dialog
    v-model="show"
    max-width="600"
    persistent
  >
    <v-card>
      <v-card-title
        class="card-dialog__title font-weight-medium pb-0"
      >
        <v-flex>
          {{ title }}
        </v-flex>
        <v-spacer />
        <v-flex shrink>
          <v-btn
            icon
            @click="closeDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-card-title>
      <v-card-subtitle class="pb-0 mt-4 mb-1">
        <div>
          <div
            class="d-inline-flex align-center"
          >
            <v-icon
              left
              size="20"
            >
              mdi-calendar
            </v-icon>
            {{ examCollectionData.appointment.date | formatDate }}
            - {{ examCollectionData.appointment.startTime | removeSecondsFromTime }}
          </div>
          <div
            class="d-inline-flex align-center ml-2"
          >
            <v-icon
              left
              size="20"
            >
              mdi-account
            </v-icon>
            {{ examCollectionData.patient.name }}
          </div>
        </div>
      </v-card-subtitle>
      <v-card-text class="py-4 px-6">
        <v-row>
          <v-col
            v-if="shouldShowProfessionalField"
            cols="12"
          >
            <v-autocomplete
              v-model="fields.professional.value"
              :items="professionalsItems"
              :search-input.sync="searchProfessional"
              label="Executante"
              item-text="name"
              item-value="id"
              hide-details
              dense
              clearable
              filled
              hide-select
              no-data-text="Digite para buscar"
              @click:clear="clearProfessional()"
            />
          </v-col>
          <v-col
            v-if="shouldShowStatusField"
            cols="12"
          >
            <v-autocomplete
              v-model="fields.status.value"
              :items="appointmentStatuses"
              label="Status"
              item-text="label"
              item-value="value"
              hide-details
              filled
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-6 pb-4">
        <v-spacer />
        <v-btn
          class="px-4 text-capitalize font-weight-lightbold"
          outlined
          @click="closeDialog()"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="ml-4 px-4 text-capitalize font-weight-lightbold"
          color="primary"
          :loading="isLoadingSave"
          :disabled="isSaveDisabled"
          @click="handleSave()"
        >
          Salvar alterações
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { debounce } from 'lodash'
import {
  isEmpty,
  isNil,
  omit,
  reject,
  values,
} from 'ramda'
import isNilOrEmpty from '@/utils/dataValidators'
import { APPOINTMENT } from 'amparo-enums'

export default {
  emits: [
    'update:showDialog',
    'update:examCollectionUpdated',
  ],
  name: 'ExamCollectionUpdateDialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    fieldNames: {
      type: Array,
      required: true,
    },
    examCollectionData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: {
        professional: {
          value: undefined,
        },
        status: {
          value: undefined,
        },
      },
      searchProfessional: null,
      professionalsItems: [],
      isLoadingSave: false,
    }
  },
  computed: {
    ...mapGetters({
      professionals: 'professional/professionals',
    }),
    show: {
      get() {
        return this.showDialog
      },
      set(value) {
        this.$emit('update:showDialog', value)
      },
    },
    appointmentStatuses() {
      return values(
        omit([
          APPOINTMENT.status.unchecked.value,
          APPOINTMENT.status.rescheduled.value,
          APPOINTMENT.status.unsuccessfulContact.value,
          APPOINTMENT.status.received.value,
        ],
        APPOINTMENT.status),
      )
    },
    shouldShowProfessionalField() {
      return this.fieldNames.includes('professional')
    },
    shouldShowStatusField() {
      return this.fieldNames.includes('status')
    },
    dataToUpdate() {
      const {
        professional: initialProfessionalData,
        appointment: initialAppointmentData,
      } = this.examCollectionData

      const initialProfessionalId = initialProfessionalData?.id || undefined

      const { professional, status } = this.fields

      const isUndefined = value => value === undefined

      const appointment = reject(isUndefined, {
        status: status.value !== initialAppointmentData.status
          ? status.value
          : undefined,
      })

      const data = {
        professionalId: professional.value !== initialProfessionalId
          ? professional.value
          : undefined,
        appointment: isEmpty(appointment) ? undefined : appointment,
      }

      return reject(isUndefined, data)
    },
    isSaveDisabled() {
      return isNilOrEmpty(this.dataToUpdate)
    },
  },
  watch: {
    searchProfessional: debounce(function searchProfessional(name) {
      if (!name) return
      this.listProfessional({ name })
    }, 600),
    professionals(professionals) {
      this.professionalsItems = reject(isNil, [...professionals, this.fields.professional.value])
    },
    async show(value) {
      if (value) {
        if (this.shouldShowProfessionalField) await this.listProfessional()
        this.fillFields()
      }
    },
  },
  mounted() {
    if (this.shouldShowProfessionalField) {
      this.listProfessional()
    }
    this.fillFields()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/setSnackbar',
      listProfessional: 'professional/listProfessional',
      listExamCollections: 'examCollection/listExamCollections',
      updateExamCollection: 'examCollection/updateExamCollection',
    }),
    closeDialog() {
      this.show = false
    },
    buidlSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    fillFields() {
      if (this.shouldShowProfessionalField) {
        this.fields.professional.value = this.examCollectionData.professional?.id
      }
      if (this.shouldShowStatusField) {
        this.fields.status.value = this.examCollectionData.appointment.status
      }
    },
    clearProfessional() {
      this.fields.professional.value = null
    },
    async handleSave() {
      this.isLoadingSave = true

      try {
        await this.updateExamCollection({
          id: this.examCollectionData.id,
          attributes: this.dataToUpdate,
        })

        this.$emit('update:examCollectionUpdated')
        this.buidlSnackbar('success', 'Exame domiciliar atualizado com sucesso!')
        this.closeDialog()
      } catch (error) {
        this.buidlSnackbar('error', 'Erro ao atualizar exame domiciliar')
      } finally {
        this.isLoadingSave = false
      }
    },
  },
}
</script>
